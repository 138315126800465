import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'
import JobPreparation from '../../Components/JobPreparation';
import MarqueeComponent from '../../Components/MarqueeComponent'
import AddOns from '../../addons.png'

const SAPBTP = () => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);


    useEffect(() => {
        // Function to create the YouTube player
        const createPlayer = () => {
            if (window.YT && window.YT.Player) {
                playerRef.current = new window.YT.Player(videoRef.current, {
                    videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
                    events: {
                        onReady: (event) => {
                            event.target.mute(); // Mute the video to allow autoplay
                            // event.target.playVideo();
                        },
                    },
                    playerVars: {
                        loop: 1,
                        controls: 0, // Hide video controls
                        modestbranding: 1, // Minimal YouTube branding
                        playlist: 'i3HdVH6gdfE', // Required for looping
                    },
                });
            }
        };

        // Load YouTube IFrame API if it's not already loaded
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // Set up callback for when the API is ready
            window.onYouTubeIframeAPIReady = () => {
                createPlayer();
            };
        } else {
            createPlayer();
        }
    }, []);

    const modulesData = [
        {
            title: 'SAP',
            points: [
                'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
            ],
            link: "/best-sap-training"
        },
        {
            title: 'SAP BASIS',
            points: [
                'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
            ],
            link: "/sap-basis-training-in-nagpur"
        },
        {
            title: 'SAP MM',
            points: [
                'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
            ],
            link: "/sap-mm"
        },
        {
            title: 'SAP FICO',
            points: [
                'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
            ],
            link: "/sap-fico"
        },
        {
            title: 'SAP ABAP',
            points: [
                'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
            ],
            link: "/sap-abap",
        },
        {
            title: 'SAP HCM',
            points: [
                'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
            ],
            link: "/sap-hcm",
        },
        {
            title: " SAP SuccessFactors",
            points: [
                "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
            ],
            link: "/sap-successfactors",
        },
        {
            title: 'SAP SCM',
            points: [
                'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
            ],
            link: "/sap-scm"
        },
        {
            title: 'SAP ARIBA',
            points: [
                'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
            ],
            link: "/sap-ariba"
        },
        {
            title: 'SAP PP',
            points: [
                'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
            ],
            link: "/sap-pp"
        },
        {
            title: 'SAP SD',
            points: [
                'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
            ],
            link: "/sap-sd"
        },
        {
            title: 'SAP FIORI',
            points: [
                'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
            ],
            link: "/sap-fiori"
        },
        {
            title: 'SAP QM',
            points: [
                'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
            ],
            link: "/sap-qm"
        },
        {
            title: 'SAP PM',
            points: [
                'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
            ],
            link: "/sap-pm"
        },
        {
            title: 'SAP WM & EWM',
            points: [
                'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
            ],
            link: "/sap-wm-ewm"
        },
        {
            title: 'SAP LE & SL',
            points: [
                'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
            ],
            link: "/sap-le-sl"
        },
    ];

    const roadmapData = [
        {
            title: 'Introduction',
            points: [
                'What is SAP?',
                'What is SAP BTP?',
                'What is SAP HANA?',
                'SAP course',
                'SAP certification',
                'SAP jobs in India',
                'SAP jobs in Nagpur',
            ]
        },
        {
            title: 'BTP Development and Integration',
            points: [
                "Understand and implement SAP BTP concepts.",
                "Build and integrate applications using SAP BTP services.",
            ]
        },
        {
            title: 'Debugging and Performance Optimization',
            points: [
                "Master debugging techniques in SAP BTP.",
                "Learn strategies for optimizing code and database queries in BTP.",
            ]
        },
        {
            title: 'Application Development',
            points: [
                'Develop cloud applications using SAP BTP tools.',
                'Customize applications to meet business requirements.',
            ]
        },
        {
            title: 'BTP Extensions',
            points: [
                'Learn to create extensions for SAP S/4HANA using SAP BTP.',
                'Understand the extension options available within BTP.',
            ]
        },
        {
            title: 'BTP Security and Compliance',
            points: [
                'Implement security best practices in SAP BTP.',
                'Understand compliance requirements and solutions.',
            ]
        },
        {
            title: 'SAP Integration Suite',
            points: [
                'Learn SAP Integration Suite for seamless data exchange.',
                'Enable communication between SAP systems and external applications.',
            ]
        },
        {
            title: 'Resume Preparation',
            points: [
                'Include relevant keywords.',
                'How to prepare a resume.',
                'How to prepare a resume for freshers.',
                'Show impact.',
                'Include soft skills.',
                'Be unique.',
            ]
        }
    ];

    const syllabus = [
        {
            title: 'Introduction',
            subpoints: [
                'What is SAP?',
                'History & Features of SAP',
                'SAP BTP Architecture',
                'SAP BTP Services Overview',
                'SAP S/4HANA Integration with BTP',
                'SAP Landscape Overview',
            ],
        },
        {
            title: 'SAP BTP Basics',
            subpoints: [
                'Introduction to SAP BTP',
                'Logon to SAP BTP Environment',
                'Using SAP BTP Cockpit',
                'Cloud Foundry Basics',
                'SAP BTP Trial Account Setup',
                'Using SAP BTP Services',
            ],
        },
        {
            title: 'BTP Development Environment',
            subpoints: [
                'Understanding the Development Environment',
                'Using SAP Business Application Studio',
                'Deployment Strategies for SAP BTP Applications',
                'Best Practices for Cloud Application Development',
            ],
        },
        {
            title: 'Integration Services',
            subpoints: [
                'Introduction to SAP Integration Suite',
                'Data Integration with SAP Data Intelligence',
                'API Management in SAP BTP',
                'Workflow Automation',
            ],
        },
        {
            title: 'BTP Security and Compliance',
            subpoints: [
                'Understanding Identity Authentication Services',
                'Role-Based Access Control (RBAC)',
                'Data Security and Privacy in SAP BTP',
            ],
        },
        {
            title: 'Advanced Application Development',
            subpoints: [
                'Introduction to SAP Fiori',
                'Building Fiori Apps with BTP',
                'Extending SAP Applications with BTP Extensions',
            ],
        },
        {
            title: 'SAP Analytics',
            subpoints: [
                'Using SAP Analytics Cloud',
                'Embedding Analytics in Applications',
                'Data Visualization Techniques',
            ],
        },
        {
            title: 'BTP Certification Preparation',
            subpoints: [
                'Certification Overview',
                'Preparation Tips',
                'Mock Tests and Resources',
            ],
        },
    ];

    const keyFeatures = [
        '130+ Hours course duration',
        '100% Job-oriented training',
        'Industry expert faculties',
        'Free demo class available',
        'Certification guidance',
        'Completed 120+ batches',
    ];

    const faqs = [
        {
            question: "What is SAP BTP?",
            answer: "SAP BTP (Business Technology Platform) is a comprehensive cloud-based platform for developing and integrating applications, analyzing data, and extending SAP solutions.",
        },
        {
            question: "What is the difference between SAP BTP and SAP?",
            answer: "SAP refers to the overall ERP system and suite of applications, while SAP BTP is a cloud-based platform focused on integration, extension, and analytics capabilities.",
        },
        {
            question: "What services does SAP BTP provide?",
            answer: "SAP BTP provides services for application development, integration, data management, analytics, and extension of SAP applications.",
        },
        {
            question: "How is SAP BTP used in real-world scenarios?",
            answer: "SAP BTP is used to build custom applications, extend existing SAP solutions, integrate data across systems, and perform advanced analytics.",
        },
        {
            question: "What certifications are available for SAP BTP?",
            answer: "Certifications such as SAP Certified Development Associate for BTP and SAP Certified Application Associate for SAP Integration Suite are available.",
        },
        {
            question: "What programming languages can be used in SAP BTP?",
            answer: "SAP BTP supports various programming languages, including Java, JavaScript, and Python.",
        },
        {
            question: "How to prepare for SAP BTP certification?",
            answer: "You can prepare by taking relevant training courses, accessing SAP learning resources, and practicing with mock tests.",
        },
        {
            question: "What is the SAP Integration Suite?",
            answer: "SAP Integration Suite is a service within SAP BTP that enables seamless integration between SAP and non-SAP systems.",
        },
        {
            question: "How secure is SAP BTP?",
            answer: "SAP BTP provides advanced security measures, including role-based access, data encryption, and compliance with global standards.",
        },
        {
            question: "Can SAP BTP be used with non-SAP systems?",
            answer: "Yes, SAP BTP allows integration with both SAP and non-SAP systems through its integration services.",
        },
    ];

    const whatsData = [
        {
            title: 'What is SAP BTP?',
            points: [
                'SAP BTP (Business Technology Platform) is a cloud-based solution for integrating, extending, and developing applications in the SAP ecosystem.',
                'It provides tools for application development, data management, and analytics.',
                'SAP BTP enables businesses to customize workflows and enhance processes for various modules, including finance, logistics, and human resources.',
                'Sapalogy offers SAP BTP training in both online and offline modes, covering real-time project-based scenarios.',
                'The training is open to IT and non-IT backgrounds, freshers, and experienced professionals alike.',
                'Sapalogy is the best training institute in Nagpur with 100% job opportunities.',
            ]
        }
    ];


    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const imageList = [
        { Image: dev1, alt: "Award 1" },
        { Image: dev2, alt: "Award 2" },
        { Image: dev3, alt: "Award 3" },
        { Image: dev4, alt: "Award 4" },
        { Image: dev5, alt: "Award 5" },
        { Image: dev6, alt: "Award 6" },
        { Image: dev7, alt: "Award 7" },
        { Image: dev8, alt: "Award 8" },
    ];

    const reviewList = [
        { Image: rev1, alt: "rev1" },
        { Image: rev2, alt: "rev1" },
        { Image: rev3, alt: "rev1" },
        { Image: rev4, alt: "rev1" },
        { Image: rev5, alt: "rev1" },
    ]

    return (
        <div className="devops-page">
            <SideDropdown roadmapData={modulesData} />
            <div className="content-container">
                <div className="devops-circle devops-circle-bottom-left"></div>
                <div className="left-content">
                    <h1>Sapalogy's Best SAP BTP Training in Nagpur - Fees, Placements</h1>
                    <p>
                        SAP BTP Training by Sapalogy offers you the best SAP BTP (Business Technology Platform) training in Nagpur for freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topic in detail and ensures students gain real-time hands-on experience.
                    </p>
                    <br />
                    <p>Our practical, job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time and aim to train you in SAP BTP with S/4 HANA in just 4 months.</p>
                    <ul className='points-list'>
                        <li>End user 1 month (ECC / S4 HANA)</li>
                        <li>Configuration 2 months (ECC / S4 HANA)</li>
                        <li>Project 1 month</li>
                    </ul>
                    <h3>All the topics will be covered in detail and also include:</h3>
                    <ul className="points-list">
                        <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
                        <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
                        <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
                    </ul>
                </div>
                <PageForm />
            </div>
            <div className="video-section">
                <div className="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/gSpX-4BOaeE?si=PbRuY4BDzE__FRbU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>

            <WhatsDevOps whatsData={whatsData} />
            <Roadmap heading="Roadmap to Learn SAP BTP" roadmapData={roadmapData} />
            <Syllabus heading="SAP BTP Training Syllabus" Syllabus={syllabus} />
            <KeyFeatures heading="SAP BTP Training" keyFeatures={keyFeatures} />
            <BatchesPage Batches={BatchesPage} />
            <JobPreparation />
            <section className="devops-certification-section">
                <h2 className="certification-heading">SAP BTP CERTIFICATION</h2>
                <div className="certification-content">
                    <div className="certification-points">
                        <ul>
                            <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
                            <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
                            <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
                        </ul>
                    </div>
                    <div className="certification-image">
                        <img src={certificateImg} alt="SAP BTP Certification" />
                    </div>
                </div>
            </section>
            <Roadmap heading="SAP modules" roadmapData={modulesData} />
            {/* devops project Section */}
            {/* <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
            <Faq Faqs={faqs} />
            <CustomMarquee />
            <img className="md:w-[70vw] m-auto w-10vw" src={AddOns} alt="" />
            <CourseOpportunities pageName="SAP_BTP" />
            <MarqueeComponent />
            <Contact />
        </div>
    );
};

export default SAPBTP;