import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'
import MarqueeComponent from '../../Components/MarqueeComponent'
import AddOns from '../../addons.png'

const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP HCM?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: ' Stakeholder Engagement',
      points: [
        'Engage with key stakeholders, including HR, IT, and business leaders.',
        'Gather feedback on current SAP HCM processes and pain points.',
      ]
    },
    {
      title: 'Cloud Migration Consideration',
      points: [
        'Assess the feasibility of migrating SAP HCM to the cloud.',
        'Explore the benefits of cloud-based solutions for scalability and accessibility.',
      ]
    },
    {
      title: 'Data Security and Compliance',
      points: [
        'Implement and enhance security measures to protect sensitive HR data.',
        'Ensure compliance with relevant data protection regulations and standards.',
      ]
    },
    {
      title: 'Analytics and Reporting',
      points: [
        'Enhance reporting capabilities for HR analytics.',
        'Implement predictive analytics for workforce planning and decision-making.',
      ]
    },
    {
      title: 'User Experience (UX) Improvements',
      points: [
        'Enhance the overall user experience of SAP HCM.',
        'Optimize user interfaces for efficiency and ease of use.',
      ]
    },
    {
      title: 'Post-Implementation Support',
      points: [
        'Develop a robust support and maintenance plan.',
        'Ensure a dedicated team for addressing issues and providing ongoing support.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];
  const modulesData = [
    {
      title: 'SAP',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
    },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const syllabus = [
    {
      title: 'SAP HCM - Introduction',
      subpoints: [
        'What is SAP',
        'SAP ERP Packages',
        'What are the different modules in SAP',
        'What is the difference between Functional and Technical SAP modules',
        'What are difference between SAP EE 4.7 and ECC 6.0?',
        'What is SAP HCM Module',
        'What are the different Sub Modules in SAP HCM SAP Logon',
        'Navigation steps',
        'System landscape',
        'SAP HR – Personnel Administration',
        'Structures in HCM',
        'Enterprise Structure',
        'Personnel Structure',
        'Organizational Structure',
      ],
    },
    {
      title: 'Over view of Enterprise Structure',
      subpoints: [
        'Client',
        'Define Company',
        'Define Company Code',
        'Assign Company Code to Company',
        'Define Personnel Area',
        'Assign Personnel Area to Company Code',
        'Define Personnel Subarea',
      ],
    },
    {
      title: 'Overview of Personnel Structure',
      subpoints: [
        'Define Employee Group',
        'Define Employee Subgroup',
        'Assign Employee Subgroup to Employee Group',
        'Define Employee Attributes',
      ],
    },
    {
      title: 'Overview of Organizational Structure Organization and Staffing',
      subpoints: [
        'Create Organizational Unit',
        'Create and Assign Position to Organizational Unit',
        'Create Job and Assign to the Position',
        'Creating Task(Job/Position/Person)',
      ],
    },
    {
      title: 'Expert Mode',
      subpoints: [
        ''
      ],
    },
    {
      title: 'Simple Maintenance',
      subpoints: [
        ''
      ],
    },
    {
      title: 'Organizational Assignment',
      subpoints: [
        'Define Administrator',
      ],
    },
    {
      title: 'Overview of Features',
      subpoints: [
        'Define Administrator Group (Feature PINCH)',

      ],
    },
    {
      title: 'Integration between Organizational Management and Personnel Administration',
      subpoints: [
        'Activate Integration (PLOGI ORGA)',
      ],
    },
    {
      title: 'SAP HR- HR Master Data',
      subpoints: [
        '',
      ],
    },
    {
      title: 'Hire an Employee',
      subpoints: [
        'HR Master Data Structure',
        'Processing HR Master Data',
        'Selecting HR Master Data',
        'Info Type menu',
      ],
    },
    {
      title: 'SAP HR- Actions/ Personnel Events',
      subpoints: [
        'Creating Action',
        'Personnel Action type',
        'Reasons for Action',
        'Step to hire an Employee',
      ],
    },
    {
      title: 'Time Management Advantages of Time Management Transaction codes for Time Recordings SAP HR -Work Schedules',
      subpoints: [
        'Group Personnel Subarea for the Work Schedules',
        'Group Personnel Subarea for the Daily Work Schedules',
        'Define Break Schedules',
        'Define Rules for variants',
        'Define Daily Work Schedule',
        'Define Period Work Schedule',
        'Define Employee Subgroup Grouping',
        'Define Groupings for the Public Holiday Calendar',
        'Set Work Schedule Rules and Work Schedules',
        'Assign your work schedule rule for –SCHKZ',
      ],
    },
    {
      title: 'Payroll –Configuration',
      subpoints: [
        'Employee Subgroup Grouping for Personnel Calculation Rule ( PCR) and Collective',
        'Agreement Provision (CAP)',
        'Check Pay Scale Type',
        'Check Pay Scale Area',
        'Check Assignment of Pay Scale Structure to Enterprise Structure',
        'Determine Default for Pay Scale Data (TARIF)',
        'Revise Pay Scale Groups and Levels',

      ],
    },
    {
      title: 'SAP HR- primary & secondary wage types',
      subpoints: [
        'Primary wage type/Dialogue wage type',
        'Secondary wage type/Technical wage type',
        'Create Wage Type Catalog',
        'Check Wage Type Group “Basic Pay”',
        'Check Wage Type Text',
        'Check Entry Permissibility per Info Type',
        'Employee Subgroups for Primary Wage Type',
        'Personnel Subarea for Primary Wage Type',
        'Define Wage Type Permissibility for each PS and ESG',
        'Check Wage Type Characteristics',
        'Revise Pay Scale Groups and Levels',
        'Revise Default Wage Type',
        'Enterprise structure to wage type model – LGMST',
        'Create Payroll Area',
        'Check default payroll area – ABKRS',
      ],
    },
    {
      title: 'Prerequisite of Payroll Run',
      subpoints: [
        'Overview of Payroll Run',
        'Period Parameter',
        'Date modifier',
        'Check Payroll Area',
        'Generate Payroll period',
        'Create Control record',
        'Hire an Employee',
      ],
    },
    {
      title: 'Payroll Run',
      subpoints: [
        'Simulation',
        'Release for Payroll',
        'Start Payroll',
        'Check Result',
        'Corrections',
        'Exit Payroll',
        'Hire Employees',
      ],
    },
    {
      title: 'Reporting',
      subpoints: [
        'Identify a Person in your Organizational Structure',
        'Display job index report for the particular job',
        'Identify and display tasks assigned to your positions',
        'Evaluate vacant position in your organizational structure',
        'Display your Organizational Plan Graphically',
        'HIS – Human Resources Information',

      ],
    },
    {
      title: 'IN ADDITION',
      subpoints: [
        '5+ PROJECTS ON SAP',
        '20+ TOPIC WISE TESTS',
        'RESUME BUILDING',
        'INTERVIEW PREPARATION',
        '6 MONTH INTERNSHIP WITH CIN NO.',
        'JOB OPPORTUNITIES',
        'ONE ON ONE CLASSROOM INTERVIEW PRACTICE',
      ],
    }
  ];

  const keyFeatures = [
    "162+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP HCM?",
      answer: "SAP HCM (Human Capital Management) is an integrated suite of applications designed to manage an organization’s HR processes, including payroll, personnel administration, and talent management.",
    },
    {
      question: "How does SAP HCM handle personnel administration?",
      answer: "SAP HCM automates personnel administration by providing a centralized system for managing employee data, organizational structures, and related processes.",
    },
    {
      question: "What is SAP HCM payroll?",
      answer: "SAP HCM payroll is a module that automates the payroll process, calculating employee salaries, taxes, and other deductions based on predefined rules and regulations.",
    },
    {
      question: "What is Talent Management in SAP HCM?",
      answer: "Talent Management in SAP HCM covers recruitment, performance management, and employee development to identify and nurture talent within an organization",
    },
    {
      question: "How does SAP HCM support time management?",
      answer: "SAP HCM’s time management module helps organizations track and manage employee work hours, absences, and leave, ensuring accurate and compliant timekeeping.",
    },
    {
      question: "What is Employee Self-Service (ESS) in SAP HCM?",
      answer: "Employee Self-Service in SAP HCM allows employees to access and manage their personal information, benefits, and other HR-related tasks through a web-based portal.",
    },
    {
      question: "What is Organizational Management in SAP HCM?",
      answer: "Organizational Management in SAP HCM helps define and maintain an organization’s structure, including departments, positions, and reporting relationships.",
    },
    {
      question: "How does SAP HCM integrate with other modules?",
      answer: "SAP HCM seamlessly integrates with other SAP modules like Finance (FI), Controlling (CO), and Material Management (MM) to ensure data consistency across the enterprise.",
    },
    {
      question: "What is Personnel Development in SAP HCM?",
      answer: "Personnel Development in SAP HCM focuses on employee training and development, helping organizations align workforce skills with business goals.",
    },
    {
      question: "How can SAP HCM improve HR processes?",
      answer: "SAP HCM streamlines HR processes, reduces manual efforts, and enhances data accuracy, ultimately improving the efficiency and effectiveness of human resource management within an organization.",
    }
  ];

  const whatsData = [
    {
      title: 'What is SAP HCM ?',
      points: [
        'SAP HCM is an integrated solution for managing human resources processes within an organization.',
        'It covers areas such as personnel administration, organizational management, and time management.',
        'SAP HCM facilitates employee data management, including hiring, promotions, and terminations.',
        'It supports the creation and maintenance of organizational structures and hierarchies.',
        'Time management features help track employee working hours, attendance, and leave.',
        'Payroll processing is a key component, ensuring accurate and timely compensation for employees.',
        'Talent management functionalities aid in identifying, developing, and retaining key talent.',
        'Sapalogy training provides SAP HCM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.',
        'IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.',
        'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best SAP HCM Training in Nagpur -Fees, Placements</h1>
          <p>
            Sapalogy offers you the best SAP HCM(Human Capital Management) training in Nagpur for freshers and experienced candidates in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topic in detail and the student is left with real-time hands-on experience.
          </p>
          <br />
          <p>
            Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP HCM with S/4 HANA in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        <PageForm />
      </div>

      {/* 
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
      <Roadmap heading="Roadmap to learn SAP HCM" roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP HCM Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP HCM Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP HCM Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here are the SAP HCM Training Classes in Nagpur Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timing.
        </p>
        <BatchesPage Batches={BatchesPage} />

        <JobPreparation />
      </div>



      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP HCM CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>


      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />
      {/* 
      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

      <CustomMarquee />
      <img className="md:w-[70vw] m-auto w-10vw" src={AddOns} alt="" />
      <CourseOpportunities pageName="SAP_HCM" />
      <MarqueeComponent />
      <Contact />

    </div>
  );
};

export default DevOpsPage;