import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import MarqueeComponent from '../../Components/MarqueeComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';
import JobPreparation from "../../Components/JobPreparation";
import CustomMarquee from "../../Components/CustomMarquee";
import CourseOpportunities from "../../Components/CourseOpportunities";
import BatchesPage from "../../Components/Batches";
import AddOns from '../../addons.png'


const SAPSCM = () => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);


    // useEffect(() => {
    //     // Function to create the YouTube player
    //     const createPlayer = () => {
    //         if (window.YT && window.YT.Player) {
    //             playerRef.current = new window.YT.Player(videoRef.current, {
    //                 videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
    //                 events: {
    //                     onReady: (event) => {
    //                         event.target.mute(); // Mute the video to allow autoplay
    //                         // event.target.playVideo();
    //                     },
    //                 },
    //                 playerVars: {
    //                     loop: 1,
    //                     controls: 0, // Hide video controls
    //                     modestbranding: 1, // Minimal YouTube branding
    //                     playlist: 'i3HdVH6gdfE', // Required for looping
    //                 },
    //             });
    //         }
    //     };

    //     // Load YouTube IFrame API if it's not already loaded
    //     if (!window.YT) {
    //         const tag = document.createElement('script');
    //         tag.src = 'https://www.youtube.com/iframe_api';
    //         const firstScriptTag = document.getElementsByTagName('script')[0];
    //         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    //         // Set up callback for when the API is ready
    //         window.onYouTubeIframeAPIReady = () => {
    //             createPlayer();
    //         };
    //     } else {
    //         createPlayer();
    //     }
    // }, []);
    const modulesData = [
        {
            title: 'SAP',
            points: [
                'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
            ],
            link: "/best-sap-training"
        },
        {
            title: 'SAP BASIS',
            points: [
                'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
            ],
            link: "/sap-basis-training-in-nagpur"
        },
        {
            title: 'SAP MM',
            points: [
                'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
            ],
            link: "/sap-mm"
        },
        {
            title: 'SAP FICO',
            points: [
                'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
            ],
            link: "/sap-fico"
        },
        {
            title: 'SAP ABAP',
            points: [
                'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
            ],
            link: "/sap-abap",
        },
        {
            title: 'SAP HCM',
            points: [
                'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
            ],
            link: "/sap-hcm",
        },
        {
            title: " SAP SuccessFactors",
            points: [
                "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
            ],
            link: "/sap-successfactors",
        },
        {
            title: 'SAP SCM',
            points: [
                'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
            ],
            link: "/sap-scm"
        },
        {
            title: 'SAP ARIBA',
            points: [
                'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
            ],
            link: "/sap-ariba"
        },
        {
            title: 'SAP PP',
            points: [
                'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
            ],
            link: "/sap-pp"
        },
        {
            title: 'SAP SD',
            points: [
                'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
            ],
            link: "/sap-sd"
        },
        {
            title: 'SAP FIORI',
            points: [
                'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
            ],
            link: "/sap-fiori"
        },
        {
            title: 'SAP QM',
            points: [
                'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
            ],
            link: "/sap-qm"
        },
        {
            title: 'SAP PM',
            points: [
                'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
            ],
            link: "/sap-pm"
        },
        {
            title: 'SAP WM & EWM',
            points: [
                'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
            ],
            link: "/sap-wm-ewm"
        },
        {
            title: 'SAP LE & SL',
            points: [
                'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
            ],
            link: "/sap-le-sl"
        },
    ];

    const roadmapData = [
        {
            title: 'Introduction',
            points: [
                'What is SAP?',
                'What is SAP SCM?',
                'What is SAP HANA?',
                'SAP course',
                'SAP certification',
                'SAP jobs in india',
                'SAP jobs in nagpur',
            ]
        },
        {
            title: 'Stakeholder Alignment',
            points: [
                'Engage key stakeholders across departments (procurement, logistics, production, etc.).',
                'Ensure alignment between business goals and SCM objectives.',
                'Communicate the benefits and impact of SAP SCM implementation.',
            ]
        },
        {
            title: 'Data Clean-up and Migration',
            points: [
                "Perform a data quality assessment and clean up existing data.",
                "Develop a data migration strategy to seamlessly transition to SAP SCM.",
                "Ensure data accuracy and integrity for effective decision-making.   ",
            ]
        },
        {
            title: 'Integration with Existing Systems',
            points: [
                "Identify integration points with other enterprise systems (ERP, CRM, etc.).",
                "Develop interfaces and integration solutions for seamless data flow.",
                "Test and validate data exchanges between SAP SCM and other systems.",
            ]
        },
        {
            title: 'Pilot Implementation',
            points: [
                'Conduct a pilot implementation with a small-scale deployment.',
                'Gather feedback from users and address any issues.',
                'Refine configurations and processes based on pilot results.',
            ]
        },
        {
            title: 'Full-Scale Rollout',
            points: [
                'Scale up the implementation to cover the entire organization.',
                'Monitor system performance and address any scalability issues.',
                'Provide ongoing support to users during the transition.',
            ]
        },
        {
            title: 'Performance Monitoring and Optimization',
            points: [
                "Establish key performance indicators (KPIs) for SCM processes.",
                "Implement monitoring tools to track and analyze performance.",
                "Continuously optimize processes based on performance metrics.",
            ]
        },
        {
            title: 'Resume preparation',
            points: [
                'Include keywords.',
                'How to prepare resume.',
                'How to prepare resume for freshers.',
                'Show impact.',
                'Include soft skills.',
                'Be unique.',
            ]
        }
    ];

    const syllabus = [
        {
            title: 'Intruduction to SAP SCM',
            subpoints: [
                "Overview of SAP SCM and its significance in business processes.",
                "Understanding the integration with other SAP modules.          ",
            ],
        },
        {
            title: 'Supply Network Planning(SNP)',
            subpoints: [
                "SNP concepts and functionalities.",
                "Demand and supply planning.",
                "SNP optimization and simulation",
            ],
        },
        {
            title: 'Advanced Planning & Optimization(APO)',
            subpoints: [
                'Overview of APO and its components.',
                'Core Interface (CIF) for data integration.',
                'APO master data and transaction data.',
            ],
        },
        {
            title: 'Demand Planning(DP)',
            subpoints: [
                'Forecasting techniques and models.',
                'Collaborative demand planning.',
                'Lifecycle Planning.',
            ],
        },
        {
            title: 'Integrated Business Planning(IBP)',
            subpoints: [
                "End-to-end planning processes.",
                "Real-time supply chain visibility.",
                "S&OP (Sales and Operations Planning) in IBP.",
            ],
        },
        {
            title: 'Warehouse Management(WM)',
            subpoints: [
                'Warehouse structure and organization.',
                'Goods receipt and goods issue processes.',
                'Inventory management and optimization.',
            ],
        },
        {
            title: 'Material Requirements Planning(MRP)',
            subpoints: [
                'MRP concepts and processes.',
                'MRP run and planning parameters.',
                'MRP evaluation and analysis.',
            ],
        },
    ];

    const keyFeatures = [
        '120+ Hours course duration',
        '100% Job oriented training',
        'Industry expert faculties',
        'Free demo class available',
        'Certification guidance',
        'Completed 200+ batches',

    ];

    const faqs = [
        {
            question: "What is SAP SCM?",
            answer: "SAP SCM (Supply Chain Management) is a suite of software that helps organizations optimize their supply chain including planning, purchasing, manufacturing and logistics.",
        },
        {
            question: "How does SAP SCM improve Supply chain visibility",
            answer: "SAP SCM affords real-time visibility into supply chain activities, allowing better tracking of inventory, demand, and order fame. This lets in quicker decisions to be made and decreases the chance of disruption.",
        },
        {
            question: "What is SAP APO in SCM?",
            answer: "SAP Advanced Planning and Optimization (APO) is part of SAP SCM that specializes in advanced supply chain making plans, supporting businesses beautify demand planning, manufacturing making plans, and optimization.",
        },
        {
            question: "How does SAP SCM support demand planning?",
            answer: "SAP SCM incorporates call for planning tools to analyze historic records, market trends, and different factors to forecast future demand appropriately. This allows organizations align their manufacturing and distribution with consumer requirements.",
        },
        {
            question: "What is SAP ECC in the context of SCM?",
            answer: "SAP ECC (Enterprise Central Component) is the middle software program that SAP SCM integrates with to offer cease-to-give up deliver chain control abilties, protecting numerous enterprise processes.",
        },
        {
            question: "How does SAP SCM enhance collaboration in the supply chain?",
            answer: "SAP SCM facilitates collaboration by permitting actual-time communique among distinct stakeholders within the supply chain. This includes providers, producers, vendors, and stores, fostering higher coordination and responsiveness.",
        },
        {
            question: "What is SAP S/4HANA and its role in SCM?",
            answer: "SAP S/4HANA is the next-generation ERP (Enterprise Resource Planning) suite from SAP. It performs a vital function in SCM via providing a unified platform for handling deliver chain procedures with more desirable velocity, efficiency, and real-time analytics.",
        },
        {
            question: "How does SAP SCM address inventory optimization?",
            answer: "SAP SCM allows optimize stock tiers via providing equipment for higher call for forecasting, order control, and stock tracking. This minimizes excess stock, reduces carrying fees, and improves general deliver chain performance.",
        },
        {
            question: "What is SAP IBP, and how does it differ from SAP APO in SCM?",
            answer: "SAP Integrated Business Planning (IBP) is a cloud-based solution that supersedes SAP APO. IBP gives better capabilities for integrated making plans, actual-time analytics, and better collaboration across the entire supply chain.",
        },
        {
            question: "How can SAP SCM contribute to sustainability in the supply chain?",
            answer: "SAP SCM supports sustainability tasks by way of optimizing transportation routes, reducing waste, and enabling higher visibility into the environmental effect of deliver chain sports. This allows agencies make extra eco-friendly choices.",
        },
    ];

    const whatsData = [
        {
            title: 'About SAP SCM Training Course',
            points: [
                'SAP SCM (Supply Chain Management) is a software suite designed to optimize and streamline end-to-end supply chain processes.',
                'Enables efficient planning, execution, coordination, and monitoring of all supply chain activities.',
                'Integrates with other SAP modules, such as ERP, to provide a comprehensive business solution.',
                'Facilitates demand planning to ensure optimal inventory levels and meet customer demand.',
                'Supports collaborative forecasting and demand sensing for accurate predictions.',
                'Manages procurement processes, including supplier relationship management and procurement analytics.',
                'Sapalogy taining provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.',
                'IT background, non IT background, freshers, experience can start their career in SAP irrespective of their backgroun',
                'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
            ]
        }
    ]

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const imageList = [
        { Image: dev1, alt: "Award 1" },
        { Image: dev2, alt: "Award 2" },
        { Image: dev3, alt: "Award 3" },
        { Image: dev4, alt: "Award 4" },
        { Image: dev5, alt: "Award 5" },
        { Image: dev6, alt: "Award 6" },
        { Image: dev7, alt: "Award 7" },
        { Image: dev8, alt: "Award 8" },
    ];

    const reviewList = [
        { Image: rev1, alt: "rev1" },
        { Image: rev2, alt: "rev1" },
        { Image: rev3, alt: "rev1" },
        { Image: rev4, alt: "rev1" },
        { Image: rev5, alt: "rev1" },
    ]

    return (
        <div className="devops-page">
            <SideDropdown roadmapData={modulesData} />
            <div className="content-container">
                <div className="devops-circle devops-circle-bottom-left"></div>
                <div className="left-content">
                    <h1>Sapalogy- Best SAP SCM Training in Nagpur -Fees, Placements</h1>
                    <p>
                        Sapalogy offers you the best SAP SCM (Supply Chain Management) training in nagpur for freshers and experience candidate in nagpur, with expert guidance and 100% guranteed placement assistance. Our SAP training course covers every technical topics in details and the student is left with real-time hand on experience.
                    </p>
                    <br />
                    <p>Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP SCM with S/4 HANA in just 4 months.</p>
                    <ul className='points-list'>
                        <li>End user 1 month (ECC / S4 HANA)</li>
                        <li>Configuration 2 months(ECC/S4 HANA)</li>
                        <li>Project 1 month</li>
                    </ul>
                    <h3>All the topics will be covered in detail and also include:</h3>
                    <ul className="points-list">
                        <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
                        <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
                        <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
                    </ul>

                </div>
                <PageForm />
            </div>

            {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
            <WhatsDevOps whatsData={whatsData} />
            <Roadmap heading="Roadmap to learn SAP SCM" roadmapData={roadmapData} />
            <Syllabus heading="SAP SCM Training Syllabus" Syllabus={syllabus} />
            <KeyFeatures heading="SAP SCM Training" keyFeatures={keyFeatures} />

            <BatchesPage Batches={BatchesPage} />

            <JobPreparation />

            <section className="devops-certification-section">
                <h2 className="certification-heading">SAP SCM CERTIFICATION</h2>
                <div className="certification-content">
                    <div className="certification-points">
                        <ul>
                            <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
                            <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
                            <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
                        </ul>
                    </div>
                    <div className="certification-image">
                        <img src={certificateImg} alt="DevOps Certification" />
                    </div>
                </div>
            </section>
            <Roadmap heading="SAP modules" roadmapData={modulesData} />

            <Faq Faqs={faqs} />

            {/* <div className="slider-container">
                <h2 className="slider-heading">SAP SCM Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

            <CustomMarquee />
            <img className="md:w-[70vw] m-auto w-10vw" src={AddOns} alt="" />
            <CourseOpportunities pageName="SAP_SCM" />
            <MarqueeComponent />
            <Contact />

        </div>
    );
};

export default SAPSCM;