import React, { useEffect, useRef } from "react";
import "../Css/DevOps.css";
import certificateImg from "../assets/certificate.webp";
import Contact from "../pages/Contact";
import IMG from "../assets/Work.webp";
import Roadmap from "../Components/Roadmap";
import WhatsDevOps from "../Components/WhatsDevOps";
import PageForm from "../Components/PageForm";
import Syllabus from "../Components/Syllabus";
import KeyFeatures from "../Components/KeyFeatures";
import Faq from "../Components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import MarqueeComponent from '../Components/MarqueeComponent'

import dev1 from "../assets/Projects/DS/1.png";
import dev2 from "../assets/Projects/DS/2.png";
import dev3 from "../assets/Projects/DS/3.png";
import dev4 from "../assets/Projects/DS/4.png";
import dev5 from "../assets/Projects/DS/5.png";
import dev6 from "../assets/Projects/DS/6.png";
import dev7 from "../assets/Projects/DS/7.png";
import dev8 from "../assets/Projects/DS/8.png";
import rev1 from "../assets/Reviews/Ds/1.png";
import rev2 from "../assets/Reviews/Ds/2.png";
import rev3 from "../assets/Reviews/Ds/3.png";
import rev4 from "../assets/Reviews/Ds/4.png";

import RegularSlider from "../Components/RegularSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import JobPreparation from "../Components/JobPreparation";
import Marq from "../Components/CustomMarquee";
import BatchesPage from "../Components/Batches";
import CourseOpportunities from "../Components/CourseOpportunities";

const DevOpsPage = () => {
  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is AI & ML?",
        "AI & ML course",
        "AI & ML certification",
        "AI & ML jobs in india",
        "AI & ML jobs in nagpur",
      ],
    },
    {
      title: "Foundational Skills",
      points: [
        "Gain expertise in obtaining and collecting data from various sources.",
        "Master techniques for cleaning and preprocessing data to ensure its quality and reliability.",
        "Explore data storage solutions and databases.",
      ],
    },
    {
      title: " Data Acquisition and Cleaning",
      points: [
        "Gain expertise in obtaining and collecting data from various sources.",
        "Master techniques for cleaning and preprocessing data to ensure its quality and reliability.",
        "Explore data storage solutions and databases.",
      ],
    },
    {
      title: "Exploratory Data Analysis (EDA)",
      points: [
        "Learn exploratory data analysis techniques to understand the structure and patterns within the data.",
        "Use data visualization tools like Matplotlib and Seaborn to create meaningful plots.",
        "Develop the ability to ask relevant questions about the data and derive insights.",
      ],
    },
    {
      title: "Machine Learning Fundamentals",
      points: [
        "Understand the basics of machine learning algorithms and their applications.",
        "Learn how to train and evaluate models using popular frameworks like scikit-learn.",
        "Explore supervised and unsupervised learning techniques.",
      ],
    },
    {
      title: " Advanced Machine Learning and Deep Learning",
      points: [
        "Dive into more advanced machine learning topics such as ensemble methods and hyperparameter tuning.",
        "Familiarize yourself with deep learning concepts and frameworks like TensorFlow or PyTorch.",
        "Experiment with neural networks and understand their architectures.",
      ],
    },
    {
      title: "Model Deployment and Communication",
      points: [
        "Learn how to deploy machine learning models to production environments.",
        "Develop skills in model interpretation and explainability.",
        "Practice effective communication of data-driven insights to both technical and non-technical stakeholders.",
      ],
    },

    {
      title: "Resume preparation",
      points: [
        "Include keywords",
        "How to prepare resume",
        "How to prepare resume for freshers.",
        "Show impact",
        "Include soft skill",
        "Be unique",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Module 1",
      subpoints: [
        "Introduction to Python",
        "Python Basics",
        "Python Functions and Packages",
        "Working with Data Structures, Arrays",
        "Vectors & Data Frames",
        "Google colab notebook",
        "Pandas, NumPy, Matplotlib, Seaborn",
      ],
    },
    {
      title: "Module 2 (1 and half weeks)",
      subpoints: [
        "Applied Statistics",
        "Descriptive Statistics",
        "Probability & Conditional Probability",
        "Hypothesis Testing",
        "Inferential Statistics",
        "Probability Distributions",
      ],
    },
    {
      title: "Self paced module",
      subpoints: [
        "EDA and Data Processing",
        "Data Types",
        "Dispersion & Skewness",
        "Uni & Multivariate Analysis",
        "Data Imputation",
        "Identifying and Normalizing Outlier",
      ],
    },
    {
      title: "Machine learning",
      subpoints: [
        "Supervised learning",
        "Linear Regression",
        "Multiple Variable Linear Regression",
        "Logistic Regression",
        "Naive Bayes Classifiers",
        "k-NN Classification",
        "Support Vector Machines",
      ],
    },
    {
      title: "Unsupervised Learning",
      subpoints: [
        "Unsupervised Learning",
        "K-means Clustering",
        "Hierarchical Clustering",
        "Dimension Reduction-PCA",
      ],
    },
    {
      title: "Ensemble Techniques",
      subpoints: ["Decision Trees", "Bagging", "Random Forests", "Boosting"],
    },
    {
      title: "Featurisation, Model Selection & Tuning",
      subpoints: [
        "Feature Engineering",
        "Model Selection and Tuning",
        "Model Performance Measures",
        "Regularising Linear Models",
        "MI Pipeline",
        "Bootstrap Sampling",
        "Grid Search Cv",
        "Randomized Search Cv",
        "K Fold Cross-validation",
      ],
    },
    {
      title: "Neural Networks and Deep Learning",
      subpoints: [
        "Introduction to Perceptron & Neural Networks",
        "Activation and Loss functions",
        "Gradient Descent",
        "Batch Normalization",
        "TensorFlow & Keras for Neural Networks",
        "Hyper Parameter Tuning",
      ],
    },
    {
      title: "Computer Vision",
      subpoints: [
        "Introduction to Convolutional Neural Networks",
        "Introduction to Images",
        "Convolution, Pooling, Padding its Mechanisms",
        "Forward Propagation & Backpropagation for CNNs",
        "CNN architectures like AlexNet, VGGNet,InceptionNet & ResNet",
        "Transfer Learning",
        "Object Detection",
        "YOLO, R-CNN, SSD",
        "Semantic Segmentation",
        "U-Net",
        "Face Recognition using Siamese Networks",
        "Instance Segmentation",
      ],
    },
    {
      title: "NLP (Natural Language Processing)",
      subpoints: [
        "Introduction to NLP",
        "Stop Words.",
        "Tokenization",
        "Stemming and Lemmatization",
        "Bag of Words Model",
        "Word Vectorizer",
        "TF-IDF",
        "POS Tagging",
        "Named Entity Recognition",
        "Introduction to Sequential data",
        "RNNs and its Mechanisms",
        "Vanishing & Exploding gradients in RNNs",
        "LSTMs – Long short-term memory",
        "GRUs – Gated Recurrent Unit",
        "LSTMs Applications",
        "Time Series Analysis",
        "LSTMs with Attention Mechanism",
        "Neural Machine Translation",
        "Advanced Language Models:",
        "Transformers, BERT, XLNet",
      ],
    },
    {
      title: "Introduction to SQL",
      subpoints: [
        "Introduction To DBMS",
        "ER Diagram",
        "Schema Design",
        "Key Constraints and Basics of Normalization.",
        "Joins",
        "Subqueries Involving Joins and Aggregations",
        "Sorting",
        "Independent Subqueries",
        "Correlated Subqueries",
        "Analytic Functions.",
        "Set Operations",
        "Grouping and Filtering",
      ],
    },
    {
      title: "Power BI",
      subpoints: [
        "Introduction of Power BI and its component",
        "Data preparation and transformation using Power Query",
        "Creating and customizing visualization",
        "Building interactive report and dashboard",
        "Power BI DAX(Data Analysis Expression)",
        "Integration of Power BI with database",
      ],
    },
    {
      title: "ChatGPT and OpenAl",
      subpoints: [
        "Overview of ChatGPT and OpenAl",
        "Timeline of NLP and Generative Al",
        "Frameworks for Understanding ChatGPTand Generative Al",
        "Implications for Work, Business, andEducation",
        "Output Modalities and Limitations",
        "Business Roles to Leverage ChatGPT",
        "Prompt Engineering for Fine-TuningOutputs",
        "Practical Demonstration and BonusSection on RLHF",
        "Introduction to Generative Al",
        "Al vs ML vs DL vs GenAl",
        "Supervised vs Unsupervised Learning.",
        "Discriminative vs Generative Al",
        "A Brief Timeline of GenAl",
        "Basics of Generative Models",
        "Large Language Models",
        "Word Vectors",
        "ChatGPT: The Development Stack",
        "Attention Mechanism",
        "Business Applications of ML, DL and GenAl",
        "Hands-on Bing Images and ChatGPT",
      ],
    },
  ];

  const keyFeatures = [
    "220+ Hours course duration",
    "100% Job oriented training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 100+ batches",
    "Certification guidance",
  ];

  const faqs = [
    {
      question: "What is Data science?",
      answer:
        "Data science is a multidisciplinary field that uses scientific methods, processes, algorithms, and systems to extract insights and knowledge from structured and unstructured data.",
    },
    {
      question: "How to become a data scientist?",
      answer:
        "To become a data scientist, acquire a strong foundation in statistics, programming (e.g., Python or R), and machine learning. Gain practical experience through projects and consider obtaining relevant education or certifications.",
    },
    {
      question: "What are the key skills for a data scientist?",
      answer:
        "Key skills include programming, statistical analysis, machine learning, data visualization, domain knowledge, and communication skills.",
    },
    {
      question: "What programming languages are used in data science?",
      answer:
        "Python and R are widely used programming languages in data science.",
    },
    {
      question:
        "What is the difference between machine learning and deep learning?",
      answer:
        "Machine learning is a broader concept involving algorithms that can learn patterns from data, while deep learning is a subset of machine learning using neural networks with many layers.",
    },
    {
      question: "How to clean and preprocess data for analysis?",
      answer:
        "Clean and preprocess data by handling missing values, removing duplicates, scaling features, and transforming variables as needed.",
    },
    {
      question: "What is the CRISP-DM model in data science?",
      answer:
        "CRISP-DM (Cross-Industry Standard Process for Data Mining) is a extensively used version that outlines the steps in the records mining approach, which encompass commercial organization knowledge, records know-how, facts training, modeling, evaluation, and deployment.",
    },
    {
      question: "What are some popular machine learning algorithms?",
      answer:
        "Popular machine learning algorithms include linear regression, decision trees, random forests, support vector machines, k-nearest neighbors, and neural networks.",
    },
  ];

  const whatsData = [
    {
      title: "What is Data Science ?",
      points: [
        "Data science is the field of study that combines domain expertise, programming skills and knowledge of mathematics and statistics to extract meaningful insights from data.",
        "Some of the techniques utilize in data science include machine learning, visualisation, pattern recognition, probability modeling data, data engineering, signal processing etc.",
        "Data science in one of the most in demand jobs of the 21st century. A majority of companies now rely on data science to make informed decisions about their future an create an action plan.",
        "Sapalogy taining provides data science training in offline and online mode. Starting end user, consulting, implementation, support with real time data science project based training.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    // { Image: rev2, alt: "rev1" },
    // { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Data Science Training in Nagpur -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">7489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">7967 Learners</span>
          </div>
          <p>
            Best Data Science Training by Sapalogy offers you the Best Data
            Science training in nagpur for freshers and experience candidate in
            Nagpur, with expert guidance and 100% guaranteed placement
            assistance. Our Data Science training course covers every technical
            topics in details and the student is left with real-time hand on
            experience.
          </p>
          <br />
          <p>
            Our practical, Job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            2+ years of field experience. We value your time as much as over.
            Hence we invented to train you in Data Science in just 7 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} />
              Chat gpt
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Generative AI
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> LLM (Large language
              models)
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Computer vision
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview
              preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Generative AI
            </li>
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Case studies
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> Multiple projects
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> Industry level projects
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} />
              With 100% job opportunities guaranted program
            </li>
          </ul>
        </div>

        <PageForm />
      </div>
      <div className="video-section">
  <div className="video-container">
    <iframe
      src="https://www.youtube.com/embed/t0_uO42Cr_w?rel=0&modestbranding=1&loop=1&playlist=t0_uO42Cr_w&autoplay=1"
      title="YouTube Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="responsive-iframe"
    ></iframe>
  </div>
</div>

      <WhatsDevOps whatsData={whatsData} />
      <Roadmap
        heading="Roadmap to learn Data Science"
        roadmapData={roadmapData}
      />
      <Syllabus heading="Data Science syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Data science Training" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />

      <section className="devops-certification-section">
        <h2 className="certification-heading">Data Science certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                Sapalogy Training Certification will serve as proof that the
                courses were completed by Sapalogy.
              </li>
              <li>
                The Data science certification offered by Sapalogy will equip
                you with valuable skills, enhancing your competitiveness in the
                job market.
              </li>
              <li>
                Sapalogy provides comprehensive guidance for your Data Science
                global certification, ensuring a 100% passing guarantee in
                examinations such as Data science Certification, Data science
                Platform Development Certification, and various other global
                exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <JobPreparation />
      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">Data Science Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>
      <Marq />
      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>

      <MarqueeComponent />	
      <CourseOpportunities pageName="DataScience" />
      <Contact />
    </div>
  );
};

export default DevOpsPage;
