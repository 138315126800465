import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'
import MarqueeComponent from '../../Components/MarqueeComponent'
import AddOns from '../../addons.png'


const SAPFIORI = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);



  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP MM?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: ' Assessment and Planning',
      points: [
        'Conduct a current-state assessment of existing SAP landscape.',
        'Define business goals and objectives for implementing SAP Fiori.',
        'Identify key stakeholders and establish a project team.',
        'Develop a high-level project plan and budget.',
      ]
    },
    {
      title: 'System Landscape Preparation',
      points: [
        'Upgrade or enhance SAP backend systems to support Fiori.',
        'Install or update SAP Gateway and ensure it’s configured properly.',
        'Establish connectivity between frontend and backend systems.',
      ]
    },
    {
      title: 'Design and Prototyping',
      points: [
        'Define user personas and user journeys.',
        'Create wireframes and prototypes for Fiori applications.',
        'Gather feedback from stakeholders and iterate on designs.',
      ]
    },
    {
      title: 'Development and Customization',
      points: [
        'Develop Fiori apps using SAPUI5 or other supported technologies.',
        'Customize standard Fiori apps to meet specific business requirements.',
        'Implement SAP Fiori elements for rapid app development.',
      ]
    },
    {
      title: 'User Acceptance Testing (UAT)',
      points: [
        'Engage key users in UAT to validate the functionality.',
        'Collect feedback and make necessary adjustments.',
        'Ensure that the user experience aligns with business expectations.',
      ]
    },
    {
      title: 'Deployment',
      points: [
        'Plan and execute a phased deployment strategy.',
        'Monitor and address any issues during the deployment.',
        'Provide end-user training and support.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];
  const modulesData = [
    {
      title: 'SAP',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
    },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const syllabus = [
    {
      title: 'Introduction to SAP Fiori',
      subpoints: [
        'Overview of SAP Fiori and its significance.',
        'Evolution of SAP Fiori.',
        'Key principles and design concepts.',
      ],
    },
    {
      title: 'SAP Fiori Architecture',
      subpoints: [
        'Architecture components, including front-end and back-end layers.,',
        'Integration with SAP S/4HANA and other SAP solutions.,',
        'OData services for communication.,',
      ],
    },
    {
      title: 'Fiori Design Guidelines',
      subpoints: [
        'UI design principles and best practices.',
        'Responsive design and adaptive layouts.',
        'Theming and branding options.',
      ],
    },
    {
      title: 'Fiori Launchpad',
      subpoints: [
        'Introduction to the Fiori Launchpad.',
        'Configuration and customization of the launchpad.',
        'Managing tiles and groups.',
      ],
    },
    {
      title: 'Fiori Elements',
      subpoints: [
        'Overview of Fiori Elements and its predefined UI patterns.',
        'List Report, Object Page, Overview Page, and Analytical List Page.',
      ],
    },
    {
      title: 'UI5 (SAPUI5) Framework',
      subpoints: [
        'Basics of SAPUI5, the underlying framework for Fiori.',
        'UI controls, data binding, and models.',
        'Routing and navigation concepts.',
      ],
    },
    {
      title: 'OData Services',
      subpoints: [
        'Understanding OData and its role in Fiori.',
        'Creating and consuming OData services.',
      ],
    },
    {
      title: 'Extensibility and Customization',
      subpoints: [
        'Customizing Fiori apps to meet specific business requirements.',
        'Extension points and tools for customization.',
      ],
    },

  ];

  const keyFeatures = [
    "123+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP Fiori?",
      answer: "SAP Fiori is a user experience (UX) design approach and set of design principles to create a consistent and intuitive user interface for SAP applications.",
    },
    {
      question: "How does Fiori enhance user experience?",
      answer: "Fiori improves user experience by providing a responsive design, role-based access, and a simplified, personalized interface for SAP applications, making them more user-friendly.",
    },
    {
      question: "What is the Fiori Launchpad?",
      answer: "Fiori Launchpad is the entry point to SAP Fiori applications. It is a single point of access where users can launch and navigate through Fiori apps.",
    },
    {
      question: "Is Fiori only for SAP S/4HANA?",
      answer: "While Fiori is closely associated with SAP S/4HANA, it can also be used with other SAP systems like ERP, CRM, and Business Suite, as well as non-SAP systems.",
    },
    {
      question: "What is the role of SAPUI5 in Fiori?",
      answer: "SAPUI5 is the underlying framework for building SAP Fiori apps. It provides a set of libraries and tools for creating responsive and consistent web applications.",
    },
    {
      question: "How can I customize Fiori apps?",
      answer: "Fiori apps can be customized using SAP Fiori Launchpad Designer, allowing organizations to tailor the apps to their specific needs by adjusting layouts, themes, and content.",
    },
    {
      question: "What is the Fiori Elements framework?",
      answer: "Fiori Elements is a framework that enables the automatic generation of Fiori apps based on OData services, reducing the need for manual coding and speeding up app development.",
    },
    {
      question: "Can Fiori apps be used on mobile devices?",
      answer: "Yes, Fiori apps are designed to be responsive and can be accessed on various devices, including smartphones and tablets, providing a consistent user experience across different screen sizes.",
    },
    {
      question: "How does Fiori contribute to digital transformation?",
      answer: "Fiori plays a key role in digital transformation by simplifying and modernizing the user interface of SAP applications, making them more accessible, intuitive, and aligned with contemporary user expectations.",
    },
    {
      question: "What is the Fiori user adoption strategy?",
      answer: "Successful Fiori user adoption involves training, change management, and effective communication to help users transition to the new interface smoothly and maximize the benefits of the Fiori experience.",
    }
  ];

  const whatsData = [
    {
      title: 'What is SAP FIORI ?',
      points: [
        'SAP Fiori is a user experience (UX) design approach to create a consistent and intuitive interface for SAP applications.',
        'It aims to enhance user productivity by providing a responsive, role-based, and personalized user experience.',
        'Fiori apps are designed to be simple, visually appealing, and accessible across various devices.',
        'The design principles include responsive design, simplicity, and a focus on the most relevant information.',
        'Fiori utilizes modern web technologies such as HTML5, CSS3, and SAPUI5 for seamless integration with SAP systems.',
        'It supports various business roles with apps categorized into transactional, analytical, and fact sheet applications.',
        'Sapalogy taining provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.',
        'IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.',
        'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best SAP FIORI Training in Nagpur -Fees, Placements</h1>
          <p>
            Sapalogy offers you the best SAP FIORI training in nagpur for freshers and experience candidate in nagpur, with expert guidance and 100% guranteed placement assistance. Our SAP training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <br />
          <p>
            Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP FIORI with S/4 HANA in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        <PageForm />
      </div>

      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div> */}

      <Roadmap heading="Roadmap to learn SAP FIORI" roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP FIORI Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP FIORI Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP FIORI Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here are the SAP HCM Training Classes in Nagpur Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timing.
        </p>
        <BatchesPage Batches={BatchesPage} />

        <JobPreparation />
      </div>



      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP FIORI CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
      <CustomMarquee />
      <img className="md:w-[70vw] m-auto w-10vw" src={AddOns} alt="" />

      <CourseOpportunities pageName="SAP_FIORI" />
      <MarqueeComponent />
      <Contact />

    </div>
  );
};

export default SAPFIORI;