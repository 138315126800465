import React, { useEffect, useRef, useState } from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import IMG from '../assets/Work.webp'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import MarqueeComponent from '../Components/MarqueeComponent'
// project slider image


// rev image
import rev1 from '../assets/Reviews/BA/1.png';
import rev2 from '../assets/Reviews/BA/2.png';
import rev3 from '../assets/Reviews/BA/3.png';
import rev4 from '../assets/Reviews/BA/4.png';
import rev5 from '../assets/Reviews/BA/5.png';
import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle,faStar } from '@fortawesome/free-solid-svg-icons';
import Marq from '../Components/CustomMarquee';
import BatchesPage from "../Components/Batches";
import CourseOpportunities from '../Components/CourseOpportunities'

const ShareMarket = () => {

  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'Start with the Basics: Understand what stocks are and how they represent ownership in companies.',
      ]
    },
    {
      title: 'Explore Investment Options:',
      points: [
        'Learn about different types of investments like stocks, bonds, mutual funds, and ETFs—what they are and how they differ.',
      ]
    },
    {
      title: 'Dig into Analysis',
      points: [
        'Dive into fundamental analysis—how to evaluate a company’s financial health and performance.'
      ]
    },
    {
      title: 'Learn Technical Skills',
      points: [
        'Explore technical analysis—using charts and indicators to predict price movements and trends'
      ]
    },
    {
      title: 'Develop Trading Strategies',
      points: [
        'Explore various trading strategies—from long-term investing to short-term trading, and understand the risks involved',
      ]
    },
    {
      title: ' Understand Market Dynamics',
      points: [
        'Grasp market psychology and how investor sentiment influences stock prices.',
      ]
    },
    {
      title: 'Navigate Regulations',
      points: [
        ' Learn about market regulations and the role of regulatory bodies in maintaining fairness and transparency.',
      ]
    },
    {
      title: 'Build and Manage Portfolios',
      points: [
        'Understand portfolio management—how to build a diversified portfolio and manage it effectively'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to Share Market',
      subpoints: [
        'Overview of Share Market',
        'Types of Financial Markets',
        'Role of Stock Exchanges',
        'Basics of Stocks and Shares,',
      ],
    },
    {
      title: 'Investment Vehicles',
      subpoints: [
        'Types of Investment Options (Stocks, Bonds, Mutual Funds, ETFs)',
        'Characteristics and Risks of Each Investment Type',
        'Benefits of Diversification',
      ],
    },
    {
      title: ' Fundamental Analysis',
      subpoints: [
        'Understanding Financial Statements (Income Statement, Balance Sheet, Cash Flow Statement)',
        'Evaluating Company Performance',
        'Economic Indicators and their Impact on Markets',
      ],
    },
    {
      title: ' Technical Analysis',
      subpoints: [
        'Introduction to Technical Analysis',
        'Chart Patterns and Trends',
        'Common Technical Indicators (Moving Averages, RSI, MACD)',
      ],
    },
    {
      title: ' Trading Strategies',
      subpoints: [
        'Long-term vs. Short-term Strategies',
        'Risk Management Techniques (Stop-loss Orders, Position Sizing)',
        'Strategies for Market Conditions (Bull vs. Bear Markets)',
      ],
    },
    {
      title: 'Market Psychology and Sentiment',
      subpoints: [
        'Understanding Investor Behavior',
        'Impact of Emotions on Investment Decisions',
        'Contrarian and Momentum Strategies',
      ],
    },
    {
      title: ' Regulatory Environment',
      subpoints: [
        'Role of Regulatory Bodies (SEC, RBI, etc.)',
        'Insider Trading and Market Manipulation Regulations',
        'Compliance and Ethical Practices',
      ],
    },
    {
      title: 'Portfolio Management',
      subpoints: [
        'Building a Diversified Portfolio',
        'Rebalancing and Monitoring Strategies',
        'Tools and Techniques for Portfolio Optimization',
      ],
    },
    {
      title: 'Practical Skills',
      subpoints: [
       ' Using Trading Platforms and Tools',
       ' Simulation Trading Exercises',
       ' Real-world Case Studies and Applications',
      ],
    },
    {
      title: 'Continuous Learning and Updates',
      subpoints: [
        'Staying Informed with Market News and Updates',
        'Networking and Learning from Industry Experts',
        'Continuing Education and Professional Development',],
    }
    
  ];

  const keyFeatures = [
    '154+ Hrs Instructor-Led Training',
    '30 Hrs Self-paced videos',
    'Live Project & Exercises',
    'Real Time Practice',
    'Flexible Schedule',
    'Certification',
  ];

  const faqs = [
    {
      question: "What is the stock market?",
      answer: "The stock market is a platform where investors can buy and sell shares of publicly traded companies.",
    },
    {
      question: "What is trading?",
      answer: "Trading involves buying and selling financial instruments, such as stocks, bonds, commodities, or currencies, with the goal of making a profit.",
    },
    {
      question: "What is the first step to start trading?",
      answer: "The first step is to educate yourself about the basics of trading, the different markets (stocks, forex, commodities, etc.), and the risks involved.",
    },
    {
      question: "How important is community support when learning about the share market?",
      answer: "Community forums and discussion groups can provide additional insights, answer questions, and offer moral support.",
    },
    {
      question: "How does the stock market work?",
      answer: "The stock market operates through exchanges where buyers and sellers trade stocks at agreed-upon prices.",
    },
    {
      question: "What is a stock exchange?",
      answer: "A stock exchange is an organized market where securities are bought and sold, such as the NYSE or NASDAQ.",
    },
    {
      question: "How do I start investing in the stock market?",
      answer: "Open a brokerage account, fund it, research stocks, and place buy orders through your brokerage platform.",
    },
    {
      question: "What is risk management in stock trading?",
      answer: "Risk management involves identifying, assessing, and prioritizing risks to minimize potential losses in trading.",
    },
    {
      question: "What is portfolio management?",
      answer: "Portfolio management is the process of selecting and managing a collection of investments to meet long-term financial goals.",
    },
    
  ];

  const whatsData = [
    {
      title: 'What is Share Market?',
      points: [
        'The share market, often called the stock market, is where investors buy and sell shares of publicly traded companies. It’s like a bustling marketplace where people trade ownership in businesses. Imagine it as a place where companies list their stocks for sale, and investors come to buy a piece of those companies.',
        'It’s dynamic and influenced by countless factors—economic news, company performance, even human emotions like fear and greed. For investors, it’s not just about buying and selling; it’s about understanding the companies they invest in, analyzing trends, and managing risks.',
        'The share market isn’t just numbers and charts; it’s about the stories behind each company and the opportunities and challenges that come with investing in them.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Share Market Training in Nagpur -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.6)</span>
            <span className="separator">|</span>
            <span className="ratings-count">4489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">4307 Learners</span>
          </div>
          <p>
          Best Share Market Training by Sapalogy offers you the Best Share Market training in Nagpur for freshers and experienced candidates, with expert guidance. Our Share Market training course covers every technical topic in detail, providing students with real-time hands-on experience.
          </p>
          <p>
          Our practical, training program not only awards you with a certificate but also imparts knowledge equivalent to approximately 2+ years of expertise. We respect your time greatly, which is why we have condensed our Share Market training into just 3 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} />Share Market Basics</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Types of Investment Vehicles (Stocks, Bonds, Mutual Funds, ETFs)</li>
            <li><FontAwesomeIcon icon={faBriefcase} />Fundamental Analysis</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> Technical Analysis</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} />Trading Strategies (Long-term vs. Short-term)</li>
            <li><FontAwesomeIcon icon={faBriefcase} />Risk Management Techniques</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> Market Psychology and Investor Sentiment</li>
          </ul>
        </div>

        <PageForm />
      </div>

      <div className="video-section">
  <div className="video-container">
    <iframe
      src="https://www.youtube.com/embed/Lv_-8z_EPUY?rel=0&modestbranding=1&loop=1&playlist=Lv_-8z_EPUY&autoplay=1"
      title="DevOps Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="responsive-iframe"
    ></iframe>
  </div>
</div>

      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn Share Market with Sapalogy" roadmapData={roadmapData} />
      <Syllabus heading="Share Market Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Share Market Training" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />



      <section className="devops-certification-section">
        <h2 className="certification-heading">Share Market CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Share Market certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>



      <Marq />

      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


      
  <MarqueeComponent />	
      
  
      <Contact />

    </div>
  );
};

export default ShareMarket;