import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../Css/Hero.css';
import config from '../config';

const Hero = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        course: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        course: '',
    });

    const [formStatus, setFormStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({ ...errors, [name]: '' });
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required.';
            isValid = false;
        } else if (formData.name.trim().length < 2) {
            newErrors.name = 'Name must be at least 2 characters long.';
            isValid = false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phone.trim()) {
            newErrors.phone = 'Phone number is required.';
            isValid = false;
        } else if (!phoneRegex.test(formData.phone.trim())) {
            newErrors.phone = 'Phone number must be 10 digits.';
            isValid = false;
        }

        if (!formData.course) {
            newErrors.course = 'Please select a course.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);
        setFormStatus('');

        const submissionData = {
            access_key: 'fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a',
            name: formData.name,
            phone: formData.phone,
            course: formData.course,
            recipient_email: 'shivanihiware77@gmail.com',
        };

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(submissionData),
            });

            const result = await response.json();

            if (result.success) {
                const dbResponse = await fetch(`${config.apiUrl}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const dbResult = await dbResponse.json();

                if (dbResult.success) {
                    setFormStatus('Form submitted successfully!');
                } else {
                    setFormStatus('Form submitted, but saving to the database failed.');
                }

                setFormData({
                    name: '',
                    phone: '',
                    course: '',
                });
            } else {
                setFormStatus('Failed to submit the form. Please try again.');
            }
        } catch (error) {
            console.error('Error during fetch:', error);
            setFormStatus('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="hero-section mt-8">
            <div className="hero-content">
                <h1>LEARN WITH SAPALOGY FOR BEST JOB OPPORTUNITY</h1>
                <ul>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Expert Instructors</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Project-based learning</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Internship Opportunities</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Certification programs</li>
                </ul>
            </div>
            <div className="enquiry-form">
                <h2>For Free Demo Enquiry Now!</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        required
                        disabled={loading}
                    />
                    {errors.name && <p className="error-message">{errors.name}</p>}

                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Your Phone Number"
                        required
                        disabled={loading}
                    />
                    {errors.phone && <p className="error-message">{errors.phone}</p>}

                    <div style={{ marginBottom: '10px' }}>
                        <select
                            id="course"
                            name="course"
                            value={formData.course}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', margin: '5px 0', color: '#000' }}
                            disabled={loading}
                        >
                            <option value="">-- Select a Course --</option>
                            <option value="SAP">SAP</option>
                            <option value="Salesforce">Salesforce</option>
                            <option value="AWS">AWS</option>
                            <option value="DevOps">DevOps</option>
                            <option value="Python">Python</option>
                            <option value="AI & ML">AI & ML</option>
                            <option value="Data Analytics">Data Analytics</option>
                            <option value="Business Analytics">Business Analytics</option>
                            <option value="ServiceNow">ServiceNow</option>
                            <option value="HR Training">HR Training</option>
                            <option value="Share Market">Share Market</option>
                        </select>
                        {errors.course && <p className="error-message">{errors.course}</p>}
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>

                {formStatus && <p className="form-status">{formStatus}</p>}
            </div>
        </section>
    );
};

export default Hero;
