import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import dev1 from '../../assets/Projects/Salesforce/1.png';
import dev2 from '../../assets/Projects/Salesforce/2.png';
import dev3 from '../../assets/Projects/Salesforce/3.png';
import dev4 from '../../assets/Projects/Salesforce/4.png';
import dev5 from '../../assets/Projects/Salesforce/5.png';
import dev6 from '../../assets/Projects/Salesforce/6.png';
import dev7 from '../../assets/Projects/Salesforce/7.png';
import dev8 from '../../assets/Projects/Salesforce/8.png';
import dev9 from '../../assets/Projects/Salesforce/9.png';
import dev10 from '../../assets/Projects/Salesforce/10.png';

import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
import RegularSlider from '../../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle,faStar } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'

import MarqueeComponent from '../../Components/MarqueeComponent'



const SalesforceDev = () => {




  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'Salesforce Development',
        'What is Salesforce Development?',
        'Salesforce Development Careers',
        'Salesforce Development course',
        'Salesforce Development certification',
        'Salesforce Development job in india',
        'Salesforce Development job in nagpur'
      ]
    },
    {
      title: 'Basics of Salesforce Development',
      points: [
        'Learn About Salesforce Development Platform basics and architecture',
        'Understand Salesforce Development Data Model, Objects and relationships',
        'Explore Salesforce Development environments like Developer Console and Salesforce Development DX',
      ]
    },
    {
      title: 'APEX Programming',
      points: [
        'Start with Apex basics such as variables, data types and control structures.',
        'Learn About Apex Classes, triggers and batch processing',
        'Understand How to write test classes and ensure code coverage',
      ]
    },
    {
      title: 'Visualforce and Lightening Components',
      points: [
        'Begin with Visualforce basics for building custom user interfaces',
        'Dive into Lightning Web Components(LWC) development',
        'Explore Lightning Design System(SLDS) for UI Styling',
        'Automate testing and validation of infrastructure changes.'
      ]
    },
    {
      title: 'Integration And APIS ',
      points: [
        'Learn About Salesforce Development APIs such as REST, SOAP and BULK APIs.',
        'Understand how to integrate Salesforce Development with external systems like databases and web services',
        'Explore tools like Salesforce Development Connect and External Objects for Data Intergration',
      ]
    },
    {
      title: 'Salesforce Development APP',
      points: [
        'Explore Building customs apps using Salesforce Development App builder',
        'Learn about declarative development with Process Builder and Flow Builder.',
        'Understand how to package and deploy apps using Salesforce Development Packaging.',
      ]
    },
    {
      title: 'Advanced Topics and Best Practices',
      points: [
        'Dive into advanced Apex topic like asynchronus processing and governor limits.',
        'Learn About Salesforce Development security best practices and data Management.',
        'Explore Optimization techniques for improving Salesforce Development performance.',
      ]
    },
    {
      title: 'JOB opportunities',
      points: [
        'Preapare for your JOB search',
        'Ready Walkin in India',
        'Tie Up refernces',
        'GET PLACED',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction To APEX Programming',
      subpoints: [
        'Basic Of C language.',
        'What is Cloud Computing',
        'APEX Introduction',
        'APEX Classes',
        'APEX Development Process',
        'APEX Development tools',
        'APEX Governor Limits',
      ],
    },
    {
      title: 'Data Types And Logic Control Statements',
      subpoints: [
        'Overview of Apex Data Types',
        'Primitive Data Types',
        'Non-Primitive Data Types',
        'Object Data Types',
        'Enum Data Types',
        'Collections Data Types',
        'APEX Operators',
        'APEX Control Statements',
        'Executiong APEX Programs',
      ],
    },
    {
      title: 'APEX Classes',
      subpoints: [
        'APEX Classes',
        'Access Modifiers',
        'APEX Class Constructors',
        'APEX Class Variables and Methods',
        'Inheritance, Sharing and Interface',
        'Accessing APEX Class',
        'APEX Keywords and Methods',
      ],
    },
    {
      title: 'Implementing SOQL And SOSL Queries',
      subpoints: [
        'Standard object and Field API Names',
        'Relationships in APEX',
        'SOQL Function, SOQL Bindings and SOQL For Loops',
        'Dynamic Query Creation',
        'Relationship Query and its Types',
        'Child to Parent Querry',
        'Parent to Child Querry',
      ],
    },
    {
      title: 'Data Manipulation Language',
      subpoints: [
        'Data Manipulation Language',
        'Loops in DML',
        'Database DML Options',
        'Transaction Control',
        'Ways to invoke DML Operations',
        'Invoking DML Operations',
        'Handling DML Errors',
      ],
    },
    {
      title: 'Triggers In APEX',
      subpoints: [
        'APEX Triggers and its Types',
        'Order of Execution of Trigger',
        'Trigger Syntax and Events',
        'Trigger Context Variables',
        'Bulkify Triggers',
        'Trigger Helper Class Pattern',
        'Best Practice of Trigger',
      ],
    },
    {
      title: 'Batch Classes',
      subpoints: [
        'Batch Class in APEX',
        'Batch Class Methods',
        'Schedule Methods In Batch Class',
        'Cronn Expressions',
        'Email Programming',
        'Future Methods',
        'Asynchronous APEX VS Synchronous APEX',
      ],
    },
    {
      title: 'Exception Handling',
      subpoints: [
        'Exeptions',
        'Exception Statements',
        'System-Defined Exception',
        'Catching Different Exception Types',
        'Try Catch and Finally Methods',
      ],
    },
    {
      title: 'APEX Testing Essentials',
      subpoints: [
        'Write and run APEX Tests',
        'Create Tests Data',
        'Running Test Units',
        'Testing Best Practices',
        'Code Coverage',
        'Test Classes Methods',
      ],
    },

  ];

  const keyFeatures = [
    "70+ Hrs Instructor Led Training",
    "Certificate guidance",
    "Mentor support",
    "Resume editing",
    "52 Hrs Project & Exercises",
    "100% Job oriented Training",
    "36 Hrs Self-placed videos",
    "Job Assistance",
    "Free demo class available",
    "Affordable fees structure",
    "Flexible Schedule",
    "Completed 1500+ batches",
  ];

  const faqs = [
    {
      question: "What is Salesforce Development?",
      answer: "Salesforce Development Development involves customizing the Salesforce Development platform to meet specific business needs through coding, integration, and app development.",
    },
    {
      question: "What Programming Language is used in Salesforce Development ?",
      answer: "Apex is the primary programming language used in Salesforce Development Development, similar to Java, for creating custom logic and functionalities.",
    },
    {
      question: "What is Visualforce in Salesforce Development ?",
      answer: "Visualforce is a markup language used to develop custom user interfaces in Salesforce Development, allowing developers to create custom pages and components.",
    },
    {
      question: "What are Lightning Components?",
      answer: "Lightning Components are a modern framework for building reusable components in Salesforce Development, providing a responsive and efficient user experience.",
    },
    {
      question: "How do I start Learning Salesforce Development Development?",
      answer: "To start learning Salesforce Development Development, begin with understanding Salesforce Development basics, then progress to Apex programming, Visualforce, Lightning Components, and integrations.",
    },
    {
      question: "What is Salesforce Development DX?",
      answer: "Salesforce Development DX (Developer Experience) is a set of tools and features designed to streamline the development lifecycle on the Salesforce Development platform, including version control, scratch orgs, and continuous integration.",
    },
    {
      question: "How can I test Salesforce Development Code?",
      answer: "Salesforce Development developers test their code using Apex test classes to ensure functionality and meet code coverage requirements for deployment.",
    },
    {
      question: "What is the AppExchange in Salesforce Development?",
      answer: "The AppExchange is Salesforce Development’s marketplace for third-party applications and solutions that extend the functionality of the Salesforce Development platform, including apps for sales, marketing, customer service and more.",
    },
    {
      question: "What are Salesforce Development Triggers?",
      answer: "Salesforce Development Triggers are Apex code that executes before or after specific events occur in Salesforce Development, such as record insertion, update, deletion, or undeletion. Triggers allow developers to automate business processes and enforce data integrity within Salesforce Development.",
    },
  ];

  const whatsData = [
    {
      title: 'What is Salesforce Development?',
      points: [
        'Salesforce Development involves customizing Salesforce Development for unique business needs.',
        'It includes coding in languages like Apex and integrating with external systems.',
        'Developers can publish apps on Salesforce Development’s AppExchange marketplace.',
        'IT background, non IT background, Freshers, Experience can start their career in salesfore Development irrrespective of their background.',
        'Start by understanding the basics of platform, get training with sapalogy and Join user group, Communities, and placement oriented training of Salesforce Development Development will give you hand experience.',
        'Becoming a Salesforce Development Development pro is achievable through unwavering hard work, dedication, and commitment.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
    { Image: dev9, alt: "Award 8" },
    { Image: dev10, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Salesforce Development Training in Nagpur -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">6489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6667 Learners</span>
          </div>
          <p>
            Salesforce Development Training by Sapalogy offers you  the Best Salesforce Development Training in Nagpur for Fresher and Experience candidate in Nagpur, with Expert Guidance and 100% Guaranteed Placement Assistance. Our  Training Course cover  every technical topics in details and the student is left with Real-Time Hands-On Experience.
          </p>
          <p>
            Our practical, Job-Oriented Training Program, will not only provide you with a certificate but also with knowledge equivalent to around 7+ years of Field Experience.We value your time as much as ours. Hence we intend to train you in Salesforce Development Development in just 2 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>

      <div className="video-section">
  <div className="video-container">
    <iframe
      src="https://www.youtube.com/embed/syzh1IxEaak?rel=0&modestbranding=1&loop=1&playlist=syzh1IxEaak&autoplay=1"
      title="DevOps Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="responsive-iframe"
    ></iframe>
  </div>
</div>

      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn Salesforce Development" roadmapData={roadmapData} />
      <Syllabus heading="Salesforce Development Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Salesforce Development" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Salesforce Development Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the  Salesforce Development Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
    
      </div>
      <BatchesPage Batches={BatchesPage} />
<JobPreparation/>


      <section className="devops-certification-section">
        <h2 className="certification-heading">Salesforce Development Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
          <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Salesforce certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your DevOps global certification, ensuring a 100% passing guarantee in examinations.</li>
              <li>Salesforce Platform Development Certification and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="Salesforce Development Certification" />
          </div>
        </div>
      </section>



      <div className="slider-container">
        <h2 className="slider-heading">Salesforce Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>

      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>
      <CustomMarquee/>

     
      <CourseOpportunities pageName="Salesforce" />
      <MarqueeComponent />	
      <Contact />

    </div>
  );
};

export default SalesforceDev;