import React, { useEffect, useRef, useState } from "react";
import "../Css/DevOps.css";
import certificateImg from "../assets/certificate.webp";
import Contact from "../pages/Contact";
import IMG from "../assets/Work.webp";
import Roadmap from "../Components/Roadmap";
import WhatsDevOps from "../Components/WhatsDevOps";
import PageForm from "../Components/PageForm";
import Syllabus from "../Components/Syllabus";
import KeyFeatures from "../Components/KeyFeatures";
import Faq from "../Components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// project slider image
import MarqueeComponent from '../Components/MarqueeComponent'
import dev1 from "../assets/Projects/Fullstack/1.png";
import dev2 from "../assets/Projects/Fullstack/2.png";
import dev3 from "../assets/Projects/Fullstack/3.png";
import dev4 from "../assets/Projects/Fullstack/4.png";
import dev5 from "../assets/Projects/Fullstack/5.png";
import dev6 from "../assets/Projects/Fullstack/6.png";
import dev7 from "../assets/Projects/Fullstack/7.png";
import dev8 from "../assets/Projects/Fullstack/8.png";

// rev image
import rev1 from "../assets/Reviews/FullStack/1.png";
import rev2 from "../assets/Reviews/FullStack/2.png";
import rev3 from "../assets/Reviews/FullStack/3.png";
import rev4 from "../assets/Reviews/FullStack/4.png";
import rev5 from "../assets/Reviews/FullStack/5.png";
import RegularSlider from "../Components/RegularSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import JobPreparation from "../Components/JobPreparation";
import Marq from "../Components/CustomMarquee";
import BatchesPage from "../Components/Batches";
import CourseOpportunities from "../Components/CourseOpportunities";

const DevOpsPage = () => {
  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is Full stack developer?",
        "Full stack developer course",
        "Full stack developer certification",
        "Full stack developer jobs in india.",
        "Full stack developer jobs in nagpur",
      ],
    },
    {
      title: " Front-end Development",
      points: [
        "Learn HTML and CSS fundamentals.",
        "Master JavaScript for dynamic web page functionalities.",
        "Explore popular front-end frameworks like React or Angular for building interactive user interfaces.",
      ],
    },
    {
      title: "Back-end Development",
      points: [
        "Understand server-side programming with Node.js or Python.",
        "Learn about databases and data storage using SQL (e.g., MySQL) or NoSQL (e.g., MongoDB).",
        "Explore RESTful APIs for communication between front-end and back-end systems.",
      ],
    },
    {
      title: "Database Management",
      points: [
        "Gain proficiency in database design principles.",
        "Learn SQL for querying and manipulating data in relational databases.",
        "Explore NoSQL databases like MongoDB for handling unstructured data.",
      ],
    },
    {
      title: " Full Stack Frameworks",
      points: [
        "Dive into full stack frameworks like MERN (MongoDB, Express.js, React, Node.js) or MEAN (MongoDB, Express.js, Angular, Node.js).",
        "Understand the architecture and components of these frameworks for building end-to-end applications.",
      ],
    },
    {
      title: " Version Control and Collaboration",
      points: [
        "Learn version control using Git and platforms like GitHub for collaborative development.",
        "Understand branching, merging, and pull requests for efficient team collaboration.",
      ],
    },
    {
      title: "Deployment and DevOps",
      points: [
        "Explore deployment strategies using platforms like Heroku, AWS, or Azure.",
        "Learn about containerization with Docker and orchestration with Kubernetes.",
        "Understand DevOps principles for continuous integration and deployment (CI/CD) pipelines.",
      ],
    },
    {
      title: "Resume Preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Hypher Text Markup Language(HTML5)",
      subpoints: [
        "Introduction HTML",
        "HTML Basics",
        "HTML Elements",
        "HTML5 Semantic",
        "HTML Attributes",
        "HTML Headings",
        "HTML Paragraph",
        "HTML styles",
        "HTML Formatting",
        "HTML Quotations",
        "HTML Computer Code",
        "HTML Comments & Colors",
        "HTML CSS, Links and Images",
        "HTML Lists",
        "HTML Blocks",
        "HTML Classes",
        "HTML Layout",
        "HTML Responsive",
        "HTML iframes",
        "HTML JavaScript",
        "HTML Head",
      ],
    },
    {
      title: "Cascading style Sheet (CSS)",
      subpoints: [
        "Introduction CSS3",
        "CSS3 syntax",
        "CSS3 How To",
        "CSS3 Colours",
        "CSS3 Backgrounds",
        "CSS3 Boarders",
        "CSS Padding",
        "CSS Height/Width",
        "CSS3 Gradients",
        "CSS3 Shadows",
        "CSS3 Text",
        "CSS3 Fonts",
        "CSS3 2D Transforms",
        "CSS3 3D Transforms",
        "CSS Links",
        "CSS Lists",
        "CSS Tables",
        "CSS Box Model",
        "CSS Outline",
        "CSS Display",
        "CSS Max-width",
        "CSS Position",
        "CSS Float",
        "CSS Inline-block",
        "CSS Align",
        "CSS Combinators",
        "CSS Pseudo-class",
        "CSS Pseudo-element",
        "CSS Navigation Bar",
        "CSS Dropdowns",
        "CSS Tooltips",
        "CSS3 Images",
        "CSS Attr Selectors",
        "CSS Forms",
        "CSS Counters",
        "CSS3 Animations",
        "CSS3 Buttons",
        "CSS3 Pagination",
        "CSS3 Multiple Columns",
        "CSS3 User Interface",
        "CSS3 Box Sizing",
        "CSS3 Filters",
        "CSS3 Media Queries.",
        "CSS3 Responsive",
      ],

      // continue......
    },
    {
      title: "Bootstrap Module",
      subpoints: [
        "Introduction to Bootstrap",
        "Bootstrap Basics",
        "Bootstrap Grids",
        "Bootstrap Themes",
        "Bootstrap CSS",
        "Bootstrap JS",
      ],
    },
    {
      title: "Javascript Module",
      subpoints: [
        "Introduction to JavaScript",
        "JavaScript Language Basics",
        "JavaScript Objects",
        "JavaScript Scope",
        "JavaScript Events",
        "JavaScript Strings",
        "JavaScript Numbers",
        "JavaScript Math",
        "JavaScript Arrays",
        "JavaScript Boolean",
        "JavaScript Comparisons",
        "JavaScript Conditions",
        "JavaScript Switch",
        "JavaScript Loops",
        "JavaScript Type Conversion",
        "JavaScript RegExp",
        "JavaScript Errors",
        "JavaScript Debugging",
        "JavaScript Hoisting",
        "JavaScript Strict Mode",
        "JavaScript Functions",
        "JavaScript Objects",
        "JavaScript Forms",
        "JavaScript HTML DOM",
        "JavaScript BOM",
      ],
    },
    {
      title: "jQuery Module Objectives",
      subpoints: [
        "Introduction to jQuery",
        "jQuery Syntax",
        "jQuery Selectors",
        "jQuery Events",
        "jQuery Effects",
        "jQuery HTML",
        "jQuery Traversing",
        "jQuery AJAX & Misc",
      ],
    },
    {
      title: "SQL Module",
      subpoints: [
        "Relational Database Concepts",
        "How to Design Your Database & Web Database Architecture",
        "Creating your web database",
        "Creating Databases and Users",
        "Introduction to MySQL Privilege System",
        "Setting Up a User for the Web",
        "Creating Database Tables",
        "Working with your MySQL database",
        "Inserting, Retrieving and Updating Data in to the Database",
        "Altering Tables after Creation",
        "Accessing your MySQL Database from the web with nodejs",
        "How Web Database Architectures Work",
        "Checking and Filtering Input Data",
        "Setting Up a connection & Querying the Database",
      ],
    },

    {
      title: "ReactJS(Building Web App using RecatJS)(Module)",
    },

    {
      title: "Nodejs",
      subpoints: [
        "Introduction to Nodejs",
        "Architecture of Nodejs Application",
        "Synchronous and Asynchronous Programming",
        "Call back Function in nodejs",
        "Promises in Nodejs",
        "Mongodb with Nodejs",
        "Design the Schema in Nodejs",
        "Design the Rest API’s",
        "GET,POST,PUT,DELETE",
        "JSON web Token Authentication in nodejs",
        "Create the Auth APP in nodejs",
        "Create the E-commerce Backend",
        "Integrated Payment Gateway",
      ],
    },
    {
      title: "EXPRESSJS (Building RESTFUL APIs Using Express)",
      subpoints: [
        "Express",
        "Restful services",
        "Introducing Express",
        "Building your First Web Server",
        "Nodemon",
        "Environment Variables",
        "Route Parameters",
        "Handling HTTP GET Request",
        "Handling HTTP POST Request",
        "Calling Endpoints Using Postman",
        "Input Validations",
        "Handling HTTP PUT Request",
        "Handling HTTP DELETE Request",
        "Project- Build the Genres API",
      ],
    },
    {
      title: "Express-Advanced Topics",
      subpoints: [
        "Middleware",
        "Creating a Custom Middleware",
        "Built-in Middleware",
        "Environments",
        "Configuration",
        "Debugging",
        "Templating Engine",
        "Database Engines",
        "Database Integration",
        "Authentication",
        "Structuring Express Applications",
      ],
    },
    {
      title: "MongoDB",
      subpoints: [
        "Introduction to MongoDB (No-sql)",
        "Collections in MongoDb",
        "Documents In mongoDb",
        "Difference between Mysql and NoSql",
        "Inserting data into database",
        "Filter queries in Mongodb Database",
        "Schema Validation in MongoDb database",
        "Indexing In collections",
        "Aggregation in MongoDb",
        "Embedded Document in MongoDb ",
      ],
    },
    {
      title: "MERN Stack Course Outline  ReactJs",
      subpoints: [
        "Introduction to ReactJS",
        "Understand ReactJSLibrary & directory",
        "React Components",
        "Types of Components",
        "Build a simple React component",
        "Component composition",
        "Component styling",
        "Add styles to your components",
        "How to pass data from one component to another",
        "Routing",
        "Using Routing to create single page app",
        "Hooks • States",
        "Hooks vs States",
        "Types of Hooks",
        "Redux",
        "Using Redux as state container for react apps",
        "React Bootstrap",
        "How to deploy ReactJS App Nodejs",
        "Introduction to Nodejs",
        "Architecture of Nodejs Application",
        "Synchronous and Asynchronous Programming",
        "Call back Function in nodejs",
        "Promises in Nodejs",
        "Mongodb with Nodejs",
        "Design the Schema in Nodejs",
        "Design the Rest API’s",
        "GET, POST, PUT, DELETE",
        "JSON web Token Authentication in nodejs",
        "Create the Auth APP in nodejs",
        "Create the E-commerce Backend",
        "Integrated Payment Gateway",
      ],
    },
  ];

  const keyFeatures = [
    "125+ Hours course duration",
    "Industry expert faculties",
    "Free demo class available",
    "100% Job oriented training",
    "Completed 115+ Batches",
    "Certification guidance",
  ];

  const faqs = [
    {
      question: "What is Full Stack developer?",
      answer:
        "This question aims to understand the role and responsibilities of a Full stack developer who works on both front-end and back-end technologies.",
    },
    {
      question: "What technologies do Full stack developers use?",
      answer:
        "People often search for the specific programming languages, frameworks, databases, and tools commonly used by Full stack developers.",
    },
    {
      question: "How to become a Full Stack developer?",
      answer:
        "This question covers the educational background, skills, and steps required to transition into a career as a Full stack developer.",
    },
    {
      question: "What are the key skills of a Full Stack developer?",
      answer:
        "Users want to know the essential skills such as HTML/CSS, JavaScript, Node.js, React, database management, version control, and deployment.",
    },
    {
      question: "What is the salary of a Full Stack developer?",
      answer:
        "This question is about the average salary range for Full stack developers based on experience, location, and industry.",
    },
    {
      question: "What are some Full stack developer interview questions?",
      answer:
        "People seek common interview questions to prepare for Full stack developer roles, covering technical, problem-solving, and behavioral aspects.",
    },
    {
      question:
        "Is it necessary to know both front-end and back-end for Full stack developement?",
      answer:
        "This question addresses the level of proficiency required in front-end and back-end technologies for Full stack developers.",
    },
    {
      question: "What are the latest trends in Full stack development?",
      answer:
        "Users are interested in staying updated with emerging technologies, frameworks, and best practices in the Full stack development domain.",
    },
    {
      question: "How to build a Full stack developer portfolio?",
      answer:
        "This question focuses on creating a strong portfolio showcasing projects, skills, and achievements relevant to Full stack development.",
    },
  ];

  const whatsData = [
    {
      title: "What is Full stack developer ?",
      points: [
        "Full stack development involves working on both front-end and back-end technologies for web applications.",
        " Sapalogy’s course covers HTML/CSS, JavaScript, React, Node.js/Python, SQL/NoSQL, and deployment.",
        "The course is available online and offline, with 100% placement assistance.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 7" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Full stack developer Training in Nagpur -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.7)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6067 Learners</span>
          </div>
          <p>
            Best Full stack developer Training by Sapalogy training institute
            offers you the best Full stack developer training in Nagpur for
            freshers and experience candidate in Nagpur, with expert guidance
            and 100% guaranteed placement assistance. Our Full stack developer
            training course covers every technical topics in details and the
            student is left with real-time hand on experience.
          </p>
          <p>
            Our practical, Job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            5+ years of field experience. We value your time as much as over.
            Hence we invented to train you in Full stack developer in just 4
            months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>

        <PageForm />
      </div>
      <div className="video-section">
  <div className="video-container">
    <iframe
      src="https://www.youtube.com/embed/9skLBO4K-zI?rel=0&modestbranding=1&loop=1&playlist=9skLBO4K-zI&autoplay=1"
      title="YouTube Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="responsive-iframe"
    ></iframe>
  </div>
</div>

      <Roadmap
        heading="Roadmap to Learn Full stack developer"
        roadmapData={roadmapData}
      />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus
        heading="Full stack developer Training Syllabus"
        Syllabus={syllabus}
      />
      <KeyFeatures
        heading="Full stack developer Training"
        keyFeatures={keyFeatures}
      />

      <BatchesPage Batches={BatchesPage} />

      <section className="devops-certification-section">
        <h2 className="certification-heading">
          Full stack developer Certification
        </h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                Sapalogy training certification will serve as proof that the
                courses were completed by Sapalogy.
              </li>
              <li>
                The Full stack developer certification offered by Sapalogy will
                equip you with valuable skills, enhancing your competitiveness
                in the job market.
              </li>
              <li>
                Sapalogy provides comprehensive guidance for your Full stack
                developer global certification, ensuring a 100% passing
                guarantee in examinations such as Full stack developer
                Certification.
              </li>
              <li>
                {" "}
                Full stack developer Platform Development Certification, and
                various other global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">Full stack developer Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>
      <JobPreparation />
      <Marq />
      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>

      <MarqueeComponent />	
      <CourseOpportunities pageName="FullStackDevelopment" />
      <Contact />
    </div>
  );
};

export default DevOpsPage;
