import React, { useState, useEffect } from "react";
import "../Css/Batches.css";
import { useModal } from "../context/ModelContext";
import Review from "../assets/sapalogy_poster.jpeg";
import config from "../config";

const Batches = () => {
  const [activeTab, setActiveTab] = useState("online");
  const { openModal } = useModal();
  const [currentBatches, setCurrentBatches] = useState([]);
  const [formStatus, setFormStatus] = useState(""); // For showing thank-you message
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loader state

  // Function to calculate valid batch dates
  const getBatchDates = () => {
    const today = new Date();
    const predefinedDates = [1, 5, 12, 19, 26]; // Predefined dates

    const nextBatchDates = [];
    for (let i = 0; i < 4; i++) {
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      let validDate = predefinedDates.find((date) => date > today.getDate());
      if (!validDate) {
        validDate = predefinedDates[0]; // Reset to the first date of the next month
        today.setMonth(currentMonth + 1);
        today.setDate(1);
      } else {
        today.setDate(validDate);
      }

      nextBatchDates.push(
        new Date(today.getFullYear(), today.getMonth(), validDate).toLocaleDateString(undefined, {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      );

      if (validDate === predefinedDates[0]) {
        today.setMonth(currentMonth + 1);
        today.setDate(1);
      }
    }

    return nextBatchDates;
  };

  useEffect(() => {
    setCurrentBatches(getBatchDates());
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", phone: "" };

    if (!/^[a-zA-Z\s]{3,}$/.test(formData.name)) {
      newErrors.name = "Name must be at least 3 characters and only contain letters.";
      isValid = false;
    }

    // Phone validation
    if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
          subject: "New Contact Form Submission",
          from_name: "Sapalogy Training",
          ...formData,
          recipient_email: "shivanihiware77@gmail.com",
        }),
      });

      const result = await response.json();

      if (result.success) {
        const dbResponse = await fetch(`${config.apiUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const dbResult = await dbResponse.json();

        if (dbResult.success) {
          setFormStatus("Form submitted successfully!");
        } else {
          setFormStatus("Form submitted, but saving to the database failed.");
        }

        setFormData({ name: "", phone: "", message: "" });
      } else {
        setFormStatus("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setFormStatus("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="batches-container">
      <div className="left-cont">
        <h2>Upcoming Batches</h2>
        <div className="tabs">
          <button
            className={activeTab === "online" ? "active" : ""}
            onClick={() => setActiveTab("online")}
          >
            Online Classroom
          </button>
          <button
            className={activeTab === "corporate" ? "active" : ""}
            onClick={() => setActiveTab("corporate")}
          >
            Offline Training
          </button>
        </div>

        <div className="content">
          {activeTab === "online" ? (
            <div className="schedule">
              {currentBatches.map((date, index) => (
                <div className="batch" key={index}>
                  <span>{date}</span>
                  <span>Mon-Sat</span>
                  <span>Flexible Timing</span>
                  <span>Weekdays Regular</span>
                  <span>1hr - 1:30hr</span>
                  <button>
                    <a href="/contact">Course Fees</a>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <form className="corporate-form" onSubmit={handleSubmit}>
              <h3>Offline Training Inquiry</h3>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </label>
              <label>
                Phone:
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </label>
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your message"
                  required
                ></textarea>
              </label>
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </button>
              {isLoading && <div className="loader"></div>}
            </form>
          )}
          {formStatus && <p className="form-status">{formStatus}</p>}
        </div>
      </div>

      <div className="right-content">
        <img src={Review} alt="Review Poster" className="review-poster" />
      </div>
    </div>
  );
};

export default Batches;
