import React, { useEffect, useRef } from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import IMG from '../assets/Work.webp'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import MarqueeComponent from '../Components/MarqueeComponent'
import rev1 from '../assets/Reviews/python/1.png';
import rev2 from '../assets/Reviews/python/2.png';
import rev3 from '../assets/Reviews/python/3.png';
import rev4 from '../assets/Reviews/python/4.png';
import rev5 from '../assets/Reviews/python/5.png';
import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle,faStar } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../Components/JobPreparation';
import Marq from '../Components/CustomMarquee';
import BatchesPage from "../Components/Batches";
import CourseOpportunities from '../Components/CourseOpportunities'

const DevOpsPage = () => {
 


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is Data analytics ?',
        'Data Analytics course',
        'Data Analytics certification',
        'Data Analytics jobs in india',
        'Data Analytics jobs in nagpur',
      ]
    },
    {
      title: ' Basics of Python',
      points: [
        'Understand basic syntax and data types (strings, numbers, lists, dictionaries).',
        'Learn about control flow (if statements, loops) and functions.',
        'Practice writing simple programs and solving coding challenges.',
      ]
    },
    {
      title: 'Intermediate Python',
      points: [
        'Dive deeper into data structures like tuples, sets, and advanced lists.',
        'Explore file handling, exception handling, and working with modules.',
        'Gain proficiency in using libraries such as NumPy for numerical computing.',
      ]
    },
    {
      title: 'Object-Oriented Programming (OOP)',
      points: [
        'Learn OOP concepts like classes, objects, inheritance, and polymorphism.',
        'Practice designing and implementing classes for real-world scenarios.',
        'Understand the importance of encapsulation, abstraction, and inheritance.',
      ]
    },
    {
      title: 'Web Development with Python',
      points: [
        'Study web frameworks like Flask or Django for building web applications.',
        'Learn about routing, templates, forms, and database integration.',
        'Create a simple web application and deploy it on a local server.',
      ]
    },
    {
      title: ' Data Science and Machine Learning',
      points: [
        'Explore data manipulation and analysis using libraries like Pandas and Matplotlib.',
        'Dive into machine learning concepts like supervised learning, unsupervised learning, and model evaluation.',
        'Apply machine learning algorithms using libraries like Scikit-Learn and TensorFlow.',
      ]
    },
    {
      title: 'Advanced Topics and Projects',
      points: [
        'Explore advanced Python concepts like decorators, generators, and context managers.',
        'Work on mini-projects to reinforce your learning and showcase your skills.',
        'Collaborate with others on open-source projects or participate in hackathons to gain practical experience.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare resume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Python for AI',
      subpoints: [
        'Introduction of Python',
        'Why python is the best for AI',
        'Basic of python',
        'Arithmetic and logical operator',
        'Input and Output Methods',
        'Data types in python',
        'String data type and operation',
        'List data types and operation',
        'Sets data type and operation',
        'Dictionary data type and operation',
        'Tuple data type and operation',
        'Integer data type and operation',
        'Condition in python: If, Elif, Else',
        'Nested Condition in python',
        'Loop in python: For Loop and While Loop',
        'Nested loop in python',
        'Pre-define Function',
        'User-define Function Unpack operators (*args & ,** kwargs)',
        'List Comprehension and lambda Function',
        'Raising and Handling Exception (Try and Except)',
        'Logging module',
        'File handling',
        'OS module',
        'F- string',
        'Oops (Object Oriented Programming)',
      ],
    },
    {
      title: 'Statistics for AI',
      subpoints: [
        ' Introduction of Statistics',
        ' Type of statistics',
        ' Population and Sampling',
        ' Descriptive Statistics',
        ' Inferential Statistics',
        ' Hypothesis Testing',
        ' Statistics with python',
      ],
    },
    {
      title: 'Python Library for AI',
      subpoints: [
        'Pandas',
        'NumPy',
        'Matplotlib',
        'Seaborn',
        'Scikit Learn',
        'Math Module',
        'Datetime',
        'Statistics module',
        'Scipy',
      ],
    },
    {
      title: 'Python with Database',
      subpoints: [
        'SQL',
        'NoSQL',
      ],
    },
    {
      title: 'Introduction of AI',
      subpoints: [
        ' Basic of Machine Learning',
        'Basic of Deep Learning',
      ],
    },
    {
      title: 'Interview Preparation',
      subpoints: [
        'Interview Question',
        'Python project',
        'Profile Building',
        'Mock Interview',
      ],
    },
    {
      title: 'Bonus Topics',
      subpoints: [
        'GitHub',
        'Chat GPT',
        'VS Code',
      ],
    },

  ];

  const keyFeatures = [
    '80+ Hours course duration',
    '100% Job oriented Training',
    'Industry expert faculties',
    'Free demo class available',
    'Completed 159+ Batches',
    'Certifaction guidance',
  ];

  const faqs = [
    {
      question: "What is Python?",
      answer: "Python is a high-level programming language known for its simplicity and readability.",
    },
    {
      question: "What are the key features of Python?",
      answer: "Key features include easy-to-read syntax, support for multiple programming paradigms, extensive standard libraries, and dynamic typing.",
    },
    {
      question: "Is Python free?",
      answer: "Yes, Python is an open-source language, and it’s freely available for download and use.",
    },
    {
      question: "What can you do with Python?",
      answer: "Python is versatile and used for web development, data analysis, artificial intelligence, automation, scientific computing, and more.",
    },
    {
      question: "How do I install Python?",
      answer: "You can download Python from the official website and follow the installation instructions for your operating system.",
    },
    {
      question: "What is the difference between Python 2 and Python 3?",
      answer: "Python 2 is an older version with limited support, while Python 3 is the current and recommended version with improvements and new features.",
    },
    {
      question: "What are Python frameworks?",
      answer: "Python frameworks like Django, Flask, and Pyramid provide structures and tools for building web applications efficiently.",
    },
    {
      question: "Is Python good for beginners?",
      answer: "Yes, Python’s readability and simplicity make it an excellent choice for beginners learning programming.",
    }
  ];

  const whatsData = [
    {
      title: 'What is Python ?',
      points: [
        'Python is a high-level programming language known for its simplicity and readability, making it easy to learn and use.',
        'It supports multiple programming paradigms, including procedural, object-oriented, and functional programming, offering flexibility in coding styles.',
        'Python has a vast ecosystem of libraries and frameworks for tasks ranging from web development to data analysis and machine learning, making it widely used across different domains.',
        'Sapalogy cover all the Python skills to get you hired in 2024.',
        'Sapalogy taining provides Python training in offline and online mode. Support with real time Python project based training.',
        'IT background, non IT background, freshers, experience can start their career in Python irrespective of their background.',
        'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Python Training in Nagpur -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.7)</span>
            <span className="separator">|</span>
            <span className="ratings-count">4489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">4907 Learners</span>
          </div>
          <p>
            Best Python Training by Sapalogy offers you the best Python training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our Python training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <br />
          <p>
            Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 4+ years of field experience. We value your time as much as over. Hence we invented to train you in Python in just 1.5 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Git hub</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Chat gpt</li>
            <li><FontAwesomeIcon icon={faBriefcase} />Interview preparation</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />Case studies</li>
            <li><FontAwesomeIcon icon={faBriefcase} />2 Months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />With 100% job opportunities guaranted program</li>
          </ul>
        </div>

        <PageForm />
      </div>

      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div> */}

      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn Python" roadmapData={roadmapData} />
      <Syllabus heading="Python Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Python Training" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />



      <section className="devops-certification-section">
        <h2 className="certification-heading">Python certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Python certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your Python global certification, ensuring a 100% passing guarantee in examinations such as Data analytics Certification, Data Analyst Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

    <JobPreparation />
    <Marq />
      <Faq Faqs={faqs} />
      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>

      <MarqueeComponent />	
      <CourseOpportunities pageName="Python" />
      <Contact />

    </div>
  );
};

export default DevOpsPage;