import React, { useEffect, useState } from "react";
import { client } from "../contentful/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { IoClose } from "react-icons/io5";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null); // For dialog box
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const entries = await client.getEntries();
        const validEntries = entries.items.filter((job) => job.fields.title);
        setJobs(validEntries);
        console.log(validEntries);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobs();
  }, []);

  const formatDate = (createdAt) => {
    const createdDate = new Date(createdAt);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return createdDate.toLocaleDateString(undefined, options);
  };

  const openDialog = (job) => {
    setSelectedJob(job);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedJob(null);
  };

  return (
    <div className="mt-20 px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Available Jobs</h1>
      <p className="text-gray-400 mb-6 text-center">
        Explore the latest job opportunities from various industries.
      </p>

      {loading ? (
        <div className="loading-container">
          <div className="skeleton-card">
            <div className="skeleton-title skeleton-loader"></div>
            <div className="skeleton-paragraph skeleton-loader"></div>
          </div>
        </div>
      ) : jobs.length === 0 ? (
        <p className="text-center text-gray-500">
          No jobs available at the moment.
        </p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {jobs.map((job) => (
            <div
              className="job-card border border-gray-300 rounded-lg p-6 shadow-sm hover:shadow-md"
              key={job.sys.id}
            >
              <img
                src={
                  job.fields.jobposter?.fields?.file?.url ||
                  "https://via.placeholder.com/200x200"
                }
                alt="Job Poster"
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
              <h2 className="job-card-title md:text-xl font-semibold mb-2">
                {job.fields.title}
              </h2>
              <p className="job-card-date text-gray-500">
                Posted on: {formatDate(job.sys.createdAt)}
              </p>
              <button
                onClick={() => openDialog(job)}
                className="view-more-btn bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600 transition"
              >
                View More
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Dialog Box */}
      {isDialogOpen && selectedJob && (
        <div className="dialog-overlay w-auto fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-12 z-50">
          <button
            onClick={closeDialog}
            className="absolute top-3 md:right-[7%] md:text-white text-red-500 p-2 z-50"
          >
            <IoClose className="text-[3rem]" />
          </button>
          <div className="dialog-box bg-white max-w-[80%] md:max-w-[78%]">
            <div className="flex flex-col md:overflow-hidden overflow-y-auto max-h-[80vh] sm:flex-row">
              <div className="md:w-[60%] border text-center sm:text-left">
                <img
                  src={
                    selectedJob.fields.jobposter?.fields?.file?.url ||
                    "https://via.placeholder.com/200x200"
                  }
                  alt="Job Poster"
                  className="w-full h-auto object-cover mb-4 sm:mb-0"
                />
              </div>
              <div className="md:w-[70%] text-start md:overflow-y-auto p-3 md:p-8 max-h-[80vh] text-black sm:pl-6">
                <h2 className="md:text-2xl text-xl text-black font-bold mb-4">
                  {selectedJob.fields.title}
                </h2>
                <p className="text-sm md:text-md">
                  {selectedJob.fields.companyName}
                </p>
                <p className="text-sm md:text-md">
                  {selectedJob.fields.location}
                </p>
                <p className="text-sm md:text-md">
                  {selectedJob.fields.experience}
                </p>

                <div className="text-gray-700 text-sm">
                  {documentToReactComponents(selectedJob.fields.jobDescription)}
                </div>

                <div className="text-black">
                  <b>📩 Interested?</b>
                  <p>DM us to learn more.</p>

                  <ul className="text-sm">
                    <li>📞Call: +91-9175978889</li>
                    <li>✉️Email: info@sapalogytraining.com</li>
                    <li>🌐Visit: www.sapalogytraining.com</li>
                  </ul>

                  <p className="text-sm">
                    ✨ Take the first step in your Journey Of Success! 🌟
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Jobs;
