import React, { useState } from "react";
import placedStudentsData from "../Placed";
import PlacedSlider from "../Components/PlacedSlider";
import SubscribeDemo from "../Components/SubscribeDemo";

const PlacedStudents = () => {
  const [showAll, setShowAll] = useState(false);
  const placements = [
    { role: "SAP ABAP Consultants", count: "430+" },
    { role: "SAP FICO Consultants", count: "610+" },
    { role: "SAP SD Consultants", count: "530+" },
    { role: "SAP MM Consultants", count: "480+" },
    { role: "SAP Basis Administrators", count: "390+" },
    { role: "SAP HANA Consultants", count: "450+" },
    { role: "SAP SuccessFactors Consultants", count: "340+" },
    { role: "SAP PP Consultants", count: "310+" },
    { role: "SAP BW/BI Developers", count: "290+" },
    { role: "SAP Project Managers", count: "280+" },
    { role: "Salesforce Administrator", count: "420+" },
    { role: "Salesforce Architect", count: "350+" },
    { role: "Salesforce Marketing Cloud Specialist", count: "300+" },
    { role: "Salesforce Einstein Analytics Consultant", count: "200+" },
    { role: "SAP Cloud Platform Consultant", count: "400+" },
    { role: "SAP S/4HANA Finance Consultant", count: "450+" },
    { role: "Java Developers", count: "532+" },
    { role: "Full Stack Engineer (MERN Stack)", count: "800+" },
    { role: "MEAN Stack Developer", count: "750+" },
    { role: "Python Developers", count: "680+" },
    { role: ".NET Developers", count: "440+" },
    { role: "AI/ML Research Scientist", count: "350+" },
    { role: "Machine Learning Ops (MLOps) Engineer", count: "410+" },
    { role: "Python Web Developer (Django/Flask)", count: "620+" },
    { role: "AWS DevOps Engineer", count: "540+" },
    { role: "DevOps Engineer (Azure DevOps)", count: "450+" },
    { role: "Kubernetes Specialist", count: "500+" },
    { role: "Docker Specialist", count: "480+" },
    { role: "PHP Developers", count: "250+" },
    { role: "Angular Developers", count: "623+" },
    { role: "React Developers", count: "701+" },
    { role: "Salesforce Developers", count: "378+" },
    { role: "Power BI Developers", count: "371+" },
    { role: "Frontend Developers", count: "1012+" },
    { role: "Full Stack Developers", count: "1819+" },
    { role: "Data Analyst", count: "1583+" },
    { role: "Data Scientist", count: "485+" },
    { role: "Database Engineers", count: "1901+" },
    { role: "Business Analysts", count: "371+" },
    { role: "AI Engineers", count: "483+" },
    { role: "Business Intelligence Developers", count: "588+" },
  ];

  const displayedStudents = showAll
    ? placedStudentsData
    : placedStudentsData.slice(0, 30);

  
    return (
        <div className="min-h-screen bg-transparent mt-[4rem] text-white font-sans">
            <header className="p-6 text-start bg-transparent shadow-lg max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-white">List of Students Successfully Placed from Sapalogy Training</h1>
                <p className="mt-4 text-lg text-gray-300">
              
In today’s fast-paced IT industry, a degree alone isn’t enough to stand out. With more graduates entering the job market and fewer opportunities, staying ahead means mastering the tools and technologies that leading companies rely on. At Sapalogy Training, we prioritize practical, real-world skills through hands-on learning experiences. Our courses are tailored to provide you with both the technical expertise and the confidence to excel in your career.
                </p>
                <p className="mt-6 text-lg text-gray-300">
                    <span className="font-bold text-xl text-yellow-300">Training and Placement:</span> 
                    At Sapalogy Training, we’re proud to have a team of expert trainers with real-world experience, many of whom have worked at leading multinational companies. Their expertise and involvement in live projects ensure our students gain practical knowledge and industry insights. Our dedicated placement team is here to guide you through every step of your job search, helping you land the role that’s right for you. Ready to boost your career? Get in touch with us today to learn more about our programs and enjoy a free demo session!</p>
            </header>

      <section className="p-6">
        <h2 className="text-2xl font-semibold mb-4">
          Students Successfully Placed Through Sapalogy Training
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-600">
            <thead className="bg-gradient-to-r from-gray-800 to-transparent text-white">
              <tr>
                <th className="border border-gray-600 p-2">Sr. No</th>
                <th className="border border-gray-600 p-2">Photo</th>
                <th className="border border-gray-600 p-2">Name</th>
                <th className="border border-gray-600 p-2">Company</th>
                <th className="border border-gray-600 p-2">Role</th>
                <th className="border border-gray-600 p-2">Skills</th>
              </tr>
            </thead>
            <tbody>
              {displayedStudents.map((student, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-300 hover:text-black transition duration-300 ease-in-out"
                >
                  <td className="border border-gray-600 p-2 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-gray-600 p-2 text-center">
                    <img
                      src={student.photo || "https://via.placeholder.com/50"} // Default placeholder if no photo
                      alt={student.name}
                      className="w-10 h-10 rounded-full mx-auto"
                      style={{
                        pointerEvents: "none", // Prevent pointer events like right-click
                      }}
                      onContextMenu={(e) => e.preventDefault()} // Disable right-click menu
                      draggable="false" // Prevent image drag
                    />
                  </td>
                  <td className="border border-gray-600 p-2">{student.name}</td>
                  <td className="border border-gray-600 p-2">
                    {student.company}
                  </td>
                  <td className="border border-gray-600 p-2">{student.role}</td>
                  <td className="border border-gray-600 p-2">
                    {student.skills.join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {!showAll && placedStudentsData.length > 30 && (
          <div className="mt-4 text-center">
            <button
              onClick={() => setShowAll(true)}
              className="text-sm text-white bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded"
            >
              Show All
            </button> 
          </div>
        )}
      </section>

      <section className="p-6">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1 bg-transparent border border-gray-600 rounded-lg shadow-md p-4">
            <h3 className="text-2xl font-bold mb-4 text-center">
              Other Career Opportunities Enabled by Sapalogy Training
            </h3>
            <ul className="list-none space-y-4">
              {[
                { category: "CTC Greater than 5 LPA", count: "4145+" },
                {
                  category: "Academic Percentage Less than 60%",
                  count: "5515+",
                },
                {
                  category: "Non-IT to IT (Career Transition)",
                  count: "2178+",
                },
                { category: "Diploma Candidates", count: "3124+" },

                { category: "Career Break / Gap Students", count: "2908+" },
                {
                  category: "Non-Engineering Students (Arts & Science)",
                  count: "4090+",
                },
                { category: "Engineering Students", count: "3401+" },
              ].map((item, index) => (
                <li
                  key={index}
                  className="relative p-4 border border-gray-600 rounded-md hover:bg-gray-300 hover:text-black hover:font-bold transition-all transform hover:scale-105"
                >
                  <div className="flex justify-between items-center">
                    <span>{item.category}</span>
                    <span className="font-extrabold">{item.count}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1">
            <PlacedSlider />
          </div>
        </div>
      </section>

      <div className="p-6">
        <h2 className="text-4xl text-white font-bold text-center mb-6">
          Placement Success Statistics
        </h2>
        <p className="text-center text-gray-400 mb-8">
          Step into the thriving world of technology with Sapalogy Training. Our
          proven placement records highlight the success stories of our
          students. Choose your path, and let Sapalogy Training guide you toward
          a rewarding career.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {placements.map((placement, index) => (
            <div
              key={index}
              className="p-6 border border-gray-600 rounded-md shadow-lg hover:bg-gray-300 hover:text-black transition-all"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} // Custom RGBA background
            >
              <h3 className="text-xl font-semibold text-center text-white">
                {placement.role}
              </h3>
              <p className="text-3xl font-bold text-center mt-2 text-white">
                {placement.count}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-10">
        <SubscribeDemo />
      </div>
    </div>
  );
};

export default PlacedStudents;
