import React, { useState } from 'react';
import "../Css/WhyUs.css"; // Ensure the CSS file is imported
import config from '../config';
const ReachUsComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    course: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validate = () => {
    const validationErrors = {};
    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.phone) validationErrors.phone = "Phone number is required";
    if (!formData.course) validationErrors.course = "Course selection is required";
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    setIsSubmitting(true);
  
    // Combine Web3Forms access key with form data
    const formSubmissionData = {
      access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
      ...formData,
    };
  
    try {
      // Step 1: Submit data to Web3Forms
      const web3FormsResponse = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formSubmissionData),
      });
  
      const web3FormsResult = await web3FormsResponse.json();
  
      if (web3FormsResult.success) {
        // Step 2: Save data to the database
        const dbResponse = await fetch(`${config.apiUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        const dbResult = await dbResponse.json();
  
        if (dbResult.success) {
          setSuccessMessage("Form submitted successfully!");
        } else {
          setSuccessMessage("Form submitted successfully, but database save failed.");
          console.error("Database error:", dbResult.message || "Unknown error");
        }
  
        // Reset form
        setFormData({
          name: "",
          phone: "",
          course: "",
        });
      } else {
        setErrorMessage("Failed to submit the form to Web3Forms. Please try again.");
        console.error("Web3Forms error:", web3FormsResult.message || "Unknown error");
      }
    } catch (error) {
      // Handle network or unexpected errors
      setErrorMessage("An error occurred. Please try again later.");
      console.error("Submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reach-us-container">
      {/* Left Section: Form */}
      <div className="left-section">
        <h2>Reach us now!</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone No.</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="course">Select Your Course</label>
            <select
              id="course"
              name="course"
              value={formData.course}
              onChange={(e) => setFormData({ ...formData, course: e.target.value })}
            >
              <option value="salesforce">Salesforce</option>
              <option value="sap">SAP</option>
              <option value="Business Analytics">Business Analytics</option>
              <option value="Data Analytics">Data Analytics</option>
              <option value="Python">Python</option>
              <option value="Full Stack Developer">Full Stack Developer</option>
              <option value="tally">Tally</option>
              <option value="ml">ML</option>
              <option value="ai_ml">AI & ML</option>
              <option value="data_science">Data Science</option>
              <option value="sharemarket">ServiceNow</option>
              <option value="HR Training">HR Training</option>
              <option value="share_market">Share Market</option>
              <option value="aws">AWS</option>
              <option value="devops">DevOps</option>
            </select>
            {errors.course && <p className="error">{errors.course}</p>}
          </div>
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>

      {/* Right Section: Content */}
      <div className="right-section">
        <h2>Why SAPalogy?</h2>
        <p>
          Be a part of our comprehensive training programs & start your journey towards becoming a Professional Developer today.
        </p>
        <ul className="points-list">
          <li><i className="icon"></i> Start learning with us!</li>
          <li><i className="icon"></i> Become industry ready</li>
          <li><i className="icon"></i> 24*7 support</li>
          <li><i className="icon"></i> Attend live webinars every week</li>
          <li><i className="icon"></i> Access to study material</li>
          <li><i className="icon"></i> Job placement assistance</li>
        </ul>
      </div>

      {/* Display success or error message */}
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ReachUsComponent;