import React, { useEffect, useRef, useState } from "react";
import "../Css/DevOps.css";
import certificateImg from "../assets/certificate.webp";
import Contact from "../pages/Contact";
import IMG from "../assets/Work.webp";
import Roadmap from "../Components/Roadmap";
import WhatsDevOps from "../Components/WhatsDevOps";
import PageForm from "../Components/PageForm";
import Syllabus from "../Components/Syllabus";
import KeyFeatures from "../Components/KeyFeatures";
import Faq from "../Components/Faq";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MarqueeComponent from "../Components/MarqueeComponent";

import dev1 from "../assets/Projects/Devops/DEV1.png";
import dev2 from "../assets/Projects/Devops/DEV2.png";
import dev3 from "../assets/Projects/Devops/DEV3.png";
import dev4 from "../assets/Projects/Devops/DEV4.png";
import dev5 from "../assets/Projects/Devops/DEV5.png";
import dev6 from "../assets/Projects/Devops/DEV6.png";
import dev7 from "../assets/Projects/Devops/DEV7.png";
import dev8 from "../assets/Projects/Devops/DEV8.png";
// rev image
import rev1 from "../assets/Reviews/DEVOPS/devrev1.png";
import rev2 from "../assets/Reviews/DEVOPS/devrev2.png";
import rev3 from "../assets/Reviews/DEVOPS/devrev3.png";
import rev4 from "../assets/Reviews/DEVOPS/devrev4.png";
import rev5 from "../assets/Reviews/DEVOPS/devrev5.png";
import RegularSlider from "../Components/RegularSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import JobPreparation from "../Components/JobPreparation";
import Marq from "../Components/CustomMarquee";
import BatchesPage from "../Components/Batches";
import CourseOpportunities from "../Components/CourseOpportunities";

const DevOpsPage = () => {
const roadmapData = [
  {
    title: "Introduction",
    points: [
      "What is ServiceNow?",
      "ServiceNow course overview",
      "ServiceNow certification paths",
      "ServiceNow jobs in India",
      "ServiceNow jobs in Nagpur",
    ],
  },
  {
    title: "Assessment and Planning",
    points: [
      "Evaluate current IT service management (ITSM) practices.",
      "Identify pain points and inefficiencies in existing workflows.",
      "Define business objectives and align them with ServiceNow capabilities.",
      "Develop a roadmap for implementing ServiceNow solutions.",
    ],
  },
  {
    title: "Platform Fundamentals",
    points: [
      "Understand the ServiceNow platform architecture.",
      "Learn about modules like ITSM, ITOM, and ITBM.",
      "Familiarize with ServiceNow workflows and task automation.",
      "Explore the ServiceNow Developer environment and tools.",
    ],
  },
  {
    title: "Configuration and Customization",
    points: [
      "Learn how to configure ServiceNow applications for business needs.",
      "Customize workflows, forms, and UI to align with processes.",
      "Utilize scripting in ServiceNow to extend platform capabilities.",
      "Develop custom applications using the ServiceNow platform.",
    ],
  },
  {
    title: "Integration",
    points: [
      "Understand ServiceNow's integration capabilities with third-party tools.",
      "Utilize REST and SOAP APIs for seamless data exchange.",
      "Implement single sign-on (SSO) and identity management solutions.",
      "Integrate ServiceNow with IT tools like monitoring and ticketing systems.",
    ],
  },
  {
    title: "Automation and Optimization",
    points: [
      "Leverage ServiceNow's automation features for repetitive tasks.",
      "Implement orchestration for efficient IT operations.",
      "Optimize workflows and reduce manual efforts through automation.",
      "Utilize reporting and analytics for data-driven decisions.",
    ],
  },
  {
    title: "Monitoring and Feedback",
    points: [
      "Set up monitoring tools to track platform performance and usage.",
      "Establish alert systems for proactive issue management.",
      "Analyze user feedback to refine ServiceNow implementations.",
      "Conduct regular assessments to ensure continuous improvement.",
    ],
  },
  {
    title: "Resume Preparation",
    points: [
      "Highlight ServiceNow certifications and expertise.",
      "Detail hands-on experience with ServiceNow modules and tools.",
      "Include project achievements and measurable outcomes.",
      "Showcase problem-solving and process optimization skills.",
      "Tailor the resume for specific ServiceNow job roles.",
    ],
  },
];

const syllabus = [
    {
      title: "Introduction to ServiceNow",
      subpoints: [
        "What is ServiceNow?",
        "History and Evolution of ServiceNow",
        "ServiceNow as a Platform",
        "Advantages of ServiceNow",
        "ServiceNow Market Demand",
        "Overview of IT Service Management (ITSM)",
        "Introduction to ServiceNow Architecture",
        "Understanding ServiceNow Instance and its Components",
      ],
    },
    {
      title: "Navigating the ServiceNow Platform",
      subpoints: [
        "Logging into ServiceNow",
        "Personalizing the User Interface",
        "Understanding the Application Navigator",
        "Working with Forms and Lists",
        "Using Filters and Search in ServiceNow",
        "Understanding ServiceNow Roles and Permissions",
        "Personalizing Dashboards and Reports",
      ],
    },
    {
      title: "ServiceNow Configuration and Customization",
      subpoints: [
        "Configuring System Properties",
        "Creating and Managing Applications in ServiceNow",
        "Understanding ServiceNow Tables and Relationships",
        "Form and List Layout Customization",
        "Working with Business Rules",
        "Client Scripts and UI Policies",
        "Understanding Update Sets for Customizations",
      ],
    },
    {
      title: "IT Service Management (ITSM) Modules",
      subpoints: [
        "Incident Management",
        "Problem Management",
        "Change Management",
        "Request Management",
        "Knowledge Management",
        "Service Catalog and Workflows",
        "Integrating ITSM Modules for Seamless Processes",
      ],
    },
    {
      title: "ServiceNow Development and Scripting",
      subpoints: [
        "Introduction to ServiceNow Scripting",
        "Server-side Scripting with Business Rules",
        "Client-side Scripting with Client Scripts",
        "Working with Script Includes",
        "Building and Using APIs in ServiceNow",
        "Introduction to Glide System API",
        "Debugging Scripts in ServiceNow",
      ],
    },
    {
      title: "Service Portal Development",
      subpoints: [
        "Introduction to ServiceNow Service Portal",
        "Customizing the Service Portal",
        "Creating and Configuring Widgets",
        "Working with Pages and Themes",
        "Integrating Service Catalog in the Service Portal",
        "Building User-Friendly Portals for End Users",
      ],
    },
    {
      title: "Workflow and Automation in ServiceNow",
      subpoints: [
        "Understanding Workflows in ServiceNow",
        "Creating and Managing Workflows",
        "Automating Tasks with Flow Designer",
        "Using Scripted Workflows for Advanced Use Cases",
        "Understanding ServiceNow Orchestration",
        "Integration with External Systems",
      ],
    },
    {
      title: "ServiceNow Integration",
      subpoints: [
        "Overview of ServiceNow Integration",
        "Integrating ServiceNow with Third-party Tools",
        "Understanding REST and SOAP APIs",
        "Building and Managing IntegrationHub Spokes",
        "Creating Custom Integrations",
      ],
    },
    {
      title: "Reporting and Analytics",
      subpoints: [
        "Introduction to ServiceNow Reporting",
        "Creating and Customizing Reports",
        "Using Performance Analytics",
        "Building Dashboards",
        "Generating Insights from Data",
        "Setting up Scheduled Reports",
      ],
    },
    {
      title: "ServiceNow Security and Administration",
      subpoints: [
        "Managing User Roles and Access Controls",
        "Working with ACLs (Access Control Lists)",
        "Setting Up Single Sign-On (SSO)",
        "ServiceNow Instance Security Best Practices",
        "Auditing and Monitoring Activities in ServiceNow",
      ],
    },
    {
      title: "Advanced Topics in ServiceNow",
      subpoints: [
        "Understanding CMDB (Configuration Management Database)",
        "CMDB Health Dashboard and Maintenance",
        "Service Mapping and Discovery",
        "GRC (Governance, Risk, and Compliance) Module",
        "HR Service Delivery (HRSD)",
        "IT Operations Management (ITOM)",
      ],
    },
    {
      title: "ServiceNow Certification Preparation",
      subpoints: [
        "Overview of ServiceNow Certifications",
        "Certified System Administrator (CSA) Exam Guide",
        "Certified Implementation Specialist (CIS) Overview",
        "Preparing for ITSM, HRSD, and Other Certifications",
        "Mock Exams and Practice Questions",
        "Tips for Certification Success",
      ],
    },
  ];
  

  const keyFeatures = [
    "120+ Hrs Instructor-Led Training",
    "Hands-on Implementation Projects",
    "Flexible Training Schedule",
    "ServiceNow Certified Trainer Sessions",
    "Real-Time Scenarios & Case Studies",
    "Job Assistance & Interview Preparation",
    "Industry Recognized Certification",
  ];
  

  const faqs = [
    {
      question: "What is ServiceNow?",
      answer:
        "ServiceNow is a cloud-based platform offering IT Service Management (ITSM), IT Operations Management (ITOM), and IT Business Management (ITBM) to help organizations streamline processes and improve operational efficiency.",
    },
    {
      question: "Why is ServiceNow important?",
      answer:
        "ServiceNow enhances workflow automation, improves collaboration, and provides a unified platform for IT services, operations, and business processes, making organizations more efficient and responsive.",
    },
    {
      question: "What are key features of ServiceNow?",
      answer:
        "Key features include incident management, change management, problem management, service catalogs, CMDB (Configuration Management Database), and workflow automation.",
    },
    {
      question: "What industries use ServiceNow?",
      answer:
        "ServiceNow is widely used across industries like healthcare, finance, retail, education, IT services, and manufacturing to manage IT services, operations, and workflows.",
    },
    {
      question: "What is the ServiceNow CMDB?",
      answer:
        "The Configuration Management Database (CMDB) in ServiceNow stores information about IT assets and their relationships, helping organizations maintain accurate and up-to-date infrastructure details.",
    },
    {
      question: "What is ITSM in ServiceNow?",
      answer:
        "IT Service Management (ITSM) in ServiceNow provides a framework for managing IT services, including incident, problem, and change management, to ensure smooth and efficient service delivery.",
    },
    {
      question: "What is the difference between ITOM and ITSM?",
      answer:
        "ITSM focuses on managing IT services like incidents and changes, while ITOM focuses on the infrastructure and operational aspects, such as monitoring, discovery, and event management.",
    },
    {
      question: "How does ServiceNow support workflow automation?",
      answer:
        "ServiceNow allows you to automate workflows using its powerful Flow Designer and Workflow Engine, enabling businesses to streamline repetitive tasks and improve efficiency.",
    },
    {
      question: "What is the Now Platform?",
      answer:
        "The Now Platform is the core of ServiceNow, providing a unified platform for building, deploying, and managing applications and workflows across the organization.",
    },
    {
      question: "What are the career prospects with ServiceNow skills?",
      answer:
        "With skills in ServiceNow, you can pursue careers as a ServiceNow Developer, Administrator, Architect, or Consultant, with opportunities in ITSM, ITOM, HR Service Delivery, and more.",
    },
  ];
  

  const whatsData = [
    {
      title: "What is ServiceNow",
      points: [
        "ServiceNow is a cloud-based platform designed to manage IT services, operations, and business workflows efficiently.",
        "It provides tools for IT service management (ITSM), IT operations management (ITOM), and IT business management (ITBM) to streamline processes and improve productivity.",
        "ServiceNow emphasizes automation and integration, enabling organizations to reduce manual efforts and enhance user experiences across various business functions.",
      ],
    },
  ];
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best ServiceNow Training in Nagpur -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.6)</span>
            <span className="separator">|</span>
            <span className="ratings-count">4089 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">4337 Learners</span>
          </div>
          <p>
    Best ServiceNow Training by Sapalogy offers you the Best ServiceNow training 
    in Nagpur for freshers and experienced candidates, with expert guidance and 
    100% guaranteed placement assistance. Our ServiceNow training course covers 
    every technical topic in detail, leaving the student with real-time hands-on experience.
</p>
<p>
    Our practical, job-oriented training program will not only provide 
    you with a certificate but also with knowledge equivalent to around 
    2+ years of field experience. We value your time as much as ours. 
    Hence, we aim to train you in ServiceNow in just 5 months.
</p>
<h3>All the topics will be covered in detail and also include:</h3>
<ul className="points-list">
    <li>
        <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
    </li>
    <li>
        <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
    </li>
    <li>
        <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
    </li>
    <li>
        <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities 
        guaranteed program
    </li>
</ul>

        
        </div>

        <PageForm />
      </div>

      <div className="video-section">
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/8n42MAY4N6o?si=_MfZX3bWKsFT24zT" 
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="responsive-iframe"
          ></iframe>
        </div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn ServiceNow" roadmapData={roadmapData} />
      <Syllabus heading="ServiceNow Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="ServiceNow Training" keyFeatures={keyFeatures} />
      <BatchesPage Batches={BatchesPage} />

      <section className="devops-certification-section">
        <h2 className="certification-heading">ServiceNow Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
          <ul>
  <li>
    ServiceNow training certification serves as proof of successful completion of courses with ServiceNow expertise.
  </li>
  <li>
    The ServiceNow certification offered equips you with valuable skills, enhancing your competitiveness in the IT job market.
  </li>
  <li>
    Comprehensive guidance is provided for global ServiceNow certifications, ensuring a 100% success guarantee in examinations.
  </li>
  <li>
    Certification includes ServiceNow Platform Development Certification and various other globally recognized exams.
  </li>
</ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <JobPreparation />

      {/* devops project Section */}
  
      <Marq />
      <Faq Faqs={faqs} />

   

      <MarqueeComponent />
      <CourseOpportunities pageName="ServiceNow" />
      <Contact />
    </div>
  );
};

export default DevOpsPage;
