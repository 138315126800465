import React, { useEffect, useRef } from "react";
import "../../Css/DevOps.css";
import certificateImg from "../../assets/certificate.webp";
import Contact from "../../pages/Contact";
import IMG from "../../assets/Work.webp";
import Roadmap from "../../Components/Roadmap";
import WhatsDevOps from "../../Components/WhatsDevOps";
import PageForm from "../../Components/PageForm";
import Syllabus from "../../Components/Syllabus";
import KeyFeatures from "../../Components/KeyFeatures";
import Faq from "../../Components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import dev1 from "../../assets/Projects/Devops/DEV1.png";
import dev2 from "../../assets/Projects/Devops/DEV2.png";
import dev3 from "../../assets/Projects/Devops/DEV3.png";
import dev4 from "../../assets/Projects/Devops/DEV4.png";
import dev5 from "../../assets/Projects/Devops/DEV5.png";
import dev6 from "../../assets/Projects/Devops/DEV6.png";
import dev7 from "../../assets/Projects/Devops/DEV7.png";
import dev8 from "../../assets/Projects/Devops/DEV8.png";
import rev1 from "../../assets/Reviews/DEVOPS/devrev1.png";
import rev2 from "../../assets/Reviews/DEVOPS/devrev2.png";
import rev3 from "../../assets/Reviews/DEVOPS/devrev3.png";
import rev4 from "../../assets/Reviews/DEVOPS/devrev4.png";
import rev5 from "../../assets/Reviews/DEVOPS/devrev5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import SideDropdown from "../../Components/SideDropdown";
import JobPreparation from "../../Components/JobPreparation";
import CustomMarquee from "../../Components/CustomMarquee";
import CourseOpportunities from "../../Components/CourseOpportunities";
import BatchesPage from "../../Components/Batches";
import MarqueeComponent from '../../Components/MarqueeComponent'
import AddOns from '../../addons.png'


const SAPPM = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // useEffect(() => {
  //     // Function to create the YouTube player
  //     const createPlayer = () => {
  //         if (window.YT && window.YT.Player) {
  //             playerRef.current = new window.YT.Player(videoRef.current, {
  //                 videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //                 events: {
  //                     onReady: (event) => {
  //                         event.target.mute(); // Mute the video to allow autoplay
  //                         // event.target.playVideo();
  //                     },
  //                 },
  //                 playerVars: {
  //                     loop: 1,
  //                     controls: 0, // Hide video controls
  //                     modestbranding: 1, // Minimal YouTube branding
  //                     playlist: 'i3HdVH6gdfE', // Required for looping
  //                 },
  //             });
  //         }
  //     };

  //     // Load YouTube IFrame API if it's not already loaded
  //     if (!window.YT) {
  //         const tag = document.createElement('script');
  //         tag.src = 'https://www.youtube.com/iframe_api';
  //         const firstScriptTag = document.getElementsByTagName('script')[0];
  //         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //         // Set up callback for when the API is ready
  //         window.onYouTubeIframeAPIReady = () => {
  //             createPlayer();
  //         };
  //     } else {
  //         createPlayer();
  //     }
  // }, []);
  const modulesData = [
    {
      title: "SAP",
      points: [
        "Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-nagpur",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is SAP?",
        "What is SAP PM?",
        "What is SAP HANA?",
        "SAP course",
        "SAP certification",
        "SAP jobs in india",
        "SAP jobs in nagpur",
      ],
    },
    {
      title: "Data Migration and Cleansing",
      points: [
        "Analyze existing data and identify data migration requirements.",
        "Cleanse and validate data to ensure accuracy and completeness.",
        "Develop a data migration strategy and execute the migration plan.",
      ],
    },
    {
      title: "Master Data Management",
      points: [
        "Define and standardize equipment, functional locations, and work center master data.",
        "Populate master data with relevant information.",
        "Implement governance processes to maintain data integrity.",
      ],
    },
    {
      title: "Mobile Workforce Enablement",
      points: [
        "Evaluate and implement mobile solutions for field technicians.",
        "Enable remote access to work orders, data collection, and reporting.",
        "Train the workforce on mobile tools and processes.",
      ],
    },
    {
      title: "Integration with External Systems",
      points: [
        "Integrate SAP PM with other enterprise systems.",
        "Implement interfaces with IoT devices for real-time equipment monitoring.",
        "Establish communication with external service providers.",
      ],
    },
    {
      title: "Performance Monitoring and Optimization",
      points: [
        "Implement key performance indicators (KPIs) for measuring PM module effectiveness.",
        "Continuously monitor and analyze system performance.",
        "Identify opportunities for optimization and enhancement.",
      ],
    },
    {
      title: "Regulatory Compliance and Reporting",
      points: [
        "Ensure SAP PM processes align with industry regulations and standards.",
        "Implement reporting tools for regulatory compliance documentation.",
        "Regularly audit and update compliance measures.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Intruduction of SAP PM",
      subpoints: [
        "Overview of SAP PM module",
        "Integration with other SAP modules",
      ],
    },
    {
      title: "Master Data in SAP PM",
      subpoints: [
        "Equipment Master",
        "Functional Locations",
        "Bill of Materials (BOM)",
        "Work Centers",
      ],
    },
    {
      title: "Maintenance Processing",
      subpoints: [
        "Maintenance Notifications",
        "Maintenance Orders",
        "Order Types and Categories",
        "Order Planning and Scheduling",
      ],
    },
    {
      title: "Preventive Maintenance",
      subpoints: [
        "Time-based and Performance-based Maintenance Plans",
        "Maintenance Task Lists",
        "Maintenance Strategies",
      ],
    },
    {
      title: "Breakdown Maintenance",
      subpoints: [
        "Handling unplanned maintenance events",
        "Breakdown Maintenance Orders",
      ],
    },
    {
      title: "Work Clearance Management",
      subpoints: ["Lockout/Tagout processes", "Permit to Work"],
    },
    {
      title: "Technical Objects",
      subpoints: [
        "Serial Numbers",
        "Measuring Points and Counters",
        "Document Management",
      ],
    },
  ];

  const keyFeatures = [
    "124+ Hours course duration",
    "100% Job oriented training",
    "Industry expert faculties",
    "Free demo class available",
    "Certification guidance",
    "Completed 200+ batches",
  ];

  const faqs = [
    {
      question: "What is SAP PM?",
      answer:
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations manage their maintenance activities, including planning, execution, and documentation.",
    },
    {
      question: "How to create a maintenance order in SAP PM?",
      answer:
        "Transaction code IW31 allows you to create a maintenance order in SAP PM. Enter relevant details such as equipment, work center, and maintenance tasks.",
    },
    {
      question: "What is preventive maintenance in SAP PM?",
      answer:
        "Preventive maintenance in SAP PM involves scheduled tasks to prevent equipment failures. It helps improve reliability and reduce downtime.",
    },
    {
      question: "How to confirm maintenance activities in SAP PM?",
      answer:
        "Transaction code IW41 is used to confirm maintenance activities. Enter the order number and confirm the completion of tasks with relevant details.",
    },
    {
      question: "What is a work center in SAP PM?",
      answer:
        "A work center in SAP PM represents a location where maintenance activities are carried out. It includes resources, tools, and personnel.",
    },
    {
      question: "How to check equipment history in SAP PM?",
      answer:
        "Transaction code IH08 allows you to display equipment history. Enter the equipment number to view maintenance-related information.",
    },
    {
      question: "Explain SAP PM notifications.",
      answer:
        "Notifications in SAP PM are used to report issues or potential problems. Transaction code IW21 helps create notifications for further assessment.",
    },
    {
      question: "What is a functional location in SAP PM?",
      answer:
        "Functional locations in SAP PM represent physical locations where maintenance activities occur, providing a structured way to organize equipment.",
    },
    {
      question: "How to perform a maintenance task list in SAP PM?",
      answer:
        "Transaction code IA05 is used to create a maintenance task list. It includes a list of tasks and associated information for maintenance planning.",
    },
    {
      question: "What is SAP PM scheduling?",
      answer:
        "Scheduling in SAP PM involves planning maintenance activities based on time, performance, or conditions. It ensures timely execution of tasks.",
    },
  ];

  const whatsData = [
    {
      title: "What is SAP PM ?",
      points: [
        "SAP PM (Plant Maintenance) is an enterprise software module. focused on managing and optimizing maintenance activities within a company.",
        "It helps organizations plan, execute, and monitor maintenance tasks for their assets and equipment.",
        "SAP PM integrates with other SAP modules to streamline business processes and enhance overall efficiency.",
        "The module covers preventive maintenance, corrective maintenance, and breakdown maintenance.",
        "It enables the scheduling and tracking of maintenance activities to minimize downtime and improve asset reliability.",
        "SAP PM provides tools for managing work orders, notifications, and service requests related to maintenance tasks.",
        "Sapalogy taining provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.",
        "IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.",
        "Sapalogy is the best training institute in nagpur with the 100% job opportunities.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best SAP PM Training in Nagpur -Fees, Placements</h1>
          <p>
            Sapalogy offers you the best SAP PM (Plant Maintenance) Training in
            Nagpur for freshers and experience candidate in Nagpur, with expert
            guidance and 100% Guaranteed placement assistance. Our SAP PM
            Training course covers every technical topics in details and the
            student is left with real-time hand on experience.
          </p>
          <br />
          <p>
            Our practical, Job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            8+ years of field experience. We value your time as much as over.
            Hence we invented to train you in SAP PM with S/4 HANA in just 4
            months.
          </p>
          <ul className="points-list">
            <li>End user 1 month (ECC / S4 HANA)</li>
            <li>Configuration 2 months(ECC/S4 HANA)</li>
            <li>Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        <PageForm />
      </div>

      {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn SAP PM" roadmapData={roadmapData} />
      <Syllabus heading="SAP PM Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP PM Training" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />

<JobPreparation/>

      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP PM CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                Sapalogy training certification will serve as proof that the
                courses were completed by Sapalogy.
              </li>
              <li>
                The SAP S/4HANA certification offered by Sapalogy will equip you
                with valuable skills, enhancing your competitiveness in the job
                market.
              </li>
              <li>
                Sapalogy provides comprehensive guidance for your SAP S/4HANA
                global certification, ensuring a 100% passing guarantee in
                examinations such as SAP Certification, SAP S/4HANA Platform
                Development Certification, and various other global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP PM Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <CustomMarquee />
      <img className="md:w-[70vw] m-auto w-10vw" src={AddOns} alt="" />
      <CourseOpportunities pageName="SAP_PM" />
      <MarqueeComponent />	
      <Contact />
    </div>
  );
};

export default SAPPM;
